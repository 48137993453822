import { Cell, Slice } from '@ton/core';

export type Ticker = string;
export type Base64 = string;

export enum Getters {
  // ADMIN
  listContracts = 'listContracts',
  getJettonContract = 'getContractAddressByOffchainId',
  pong = 'getPong',
  // JETTON (TOKEN)
  getTokenBuyPrice = 'getTokenBuyPrice',
}

type GetJettonContractTuple = ['num', number];

export type StackTuple = GetJettonContractTuple | [];

export interface CreateContractInput {
  memeId: string;
  name: string;
  description: string;
  symbol: Ticker;
  image: string;
}

export type MetadataInput = Omit<CreateContractInput, 'memeId'>;

export interface Signature {
  signature: Slice;
  nonce: Cell;
}

export interface JettonContractMessageInput {
  ownerAddress: string;
  memeId: bigint;
  metadata: Cell;
  sig: Signature;
}

export interface BuyTokenInput {
  ownerAddress: string;
  memeId: bigint;
  amount: bigint;
}

interface TransferTokenInput {
  memeId: bigint;
  amount: bigint;
}

export type SellTokenInput = TransferTokenInput;

// RESPONSES
type GetContractAddressByOffchainIdResponseTuple = [
  string,
  { object: { data: { b64: string } } },
];

export interface GetContractAddressByOffchainIdResponse {
  stack: GetContractAddressByOffchainIdResponseTuple[];
}
