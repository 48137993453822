import { TradingTokenSearchField } from '../../../replicant/features/tradingMeme/tradingMeme.asyncGetters';
import { TradingSearchResult } from '../../../replicant/features/tradingMeme/tradingMeme.properties';
import { REPLICANT_UPLOAD_LENGTH_LIMIT } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { TradingTokenListing } from '../../../replicant/features/tradingMeme/types';
import { Optional, Fn } from '../../types';

export const tokenListFilters = {
  searchable: {
    Hot: 'hotTxsCount',
    Top: 'supply',
    New: 'availableAt',
  } satisfies Record<string, TradingTokenSearchField>,
  user: {
    Created: '',
    Owned: '',
    Farming: '',
  },
};
export type SearchFilters = keyof typeof tokenListFilters.searchable;
export type UserMemeFilters = keyof typeof tokenListFilters.user;
export type MemeFilters = SearchFilters | UserMemeFilters;

export type GetTokenStrategy =
  | 'fetch'
  | 'forceFetch'
  | 'fetchAndUpdate'
  | 'cacheOnly';

// todo carles create: we need to localize titles here (?)
export const newTokenFormConfig = {
  name: {
    title: 'Token name',
    placeholder: 'Pick a token name',
    validate: (str: string) => str.length > 0,
    required: true,
  },
  ticker: {
    title: 'Token ticker',
    placeholder: 'PEPE',
    // limit max 8 chars
    transformInput: (value: string) => value.toUpperCase().substring(0, 8),
    validate: (str: string) =>
      str.length >= 3 && str.length <= 8 && /^[a-zA-Z0-9]+$/.test(str),
    required: true,
  },
  description: {
    title: 'Token description',
    placeholder: 'Describe your token',
    validate: (str: string) => str.length > 0,
    required: true,
  },
  image: {
    title: 'Token image',
    placeholder: 'Tap to select image',
    required: true,
    // dataOnly: true,
    validate: (str: string) => {
      const file = new File([str], 'filename');
      // @todo: is it possible to increase this limit?
      return file.size <= REPLICANT_UPLOAD_LENGTH_LIMIT;
    },
  },

  // links
  twitterLink: {
    title: 'X (Twitter) Link - https://x.com/',
    placeholder: '[Optional]',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://x.com/'),
    required: false,
  },
  telegramChannelLink: {
    title: 'Telegram channel - https://t.me/',
    placeholder: ' [Optional]',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://t.me/'),
    required: false,
  },
  telegramChatLink: {
    title: 'Telegram chat - https://t.me/joinchat/',
    placeholder: '[Optional]',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://t.me/joinchat/'),
    required: false,
  },
  websiteLink: {
    title: 'Website - https://mycoolwebsite.fun',
    placeholder: '[Optional]',
    validate: (str: string) => str.length === 0 || str.startsWith('https://'),
    required: false,
  },
} as const;

export const updateTokenFormConfig = {
  telegramChannelLink: {
    title: 'Telegram channel link',
    placeholder: 'Paste Link',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://t.me/'),
    required: false,
  },
  telegramChatLink: {
    title: 'Telegram chat link',
    placeholder: 'Paste Link',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://t.me/joinchat/'),
    required: false,
  },
  twitterLink: {
    title: 'Twitter link',
    placeholder: 'Paste Link',
    validate: (str: string) =>
      str.length === 0 || str.startsWith('https://x.com/'),
    required: false,
  },
} as const;
