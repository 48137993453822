import './DrawerTiktokGameOver.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import { UIEvents } from '../../../../data/Controllers/UIController/UITypes';
import { useEffect, useState, useMemo } from 'react';
import { isMobile, isMobileEmulatedByBrowser } from '../../../../data/device';
import { largeNumberToLetter } from '../../../../replicant/utils/numbers';
import { getTMGTappingMaxTickets } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';

export const DrawerTiktokGameOver = () => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'DrawerTiktokGameOver',
    listener: app.ui.attachEventListener(UIEvents.OnTappingUpdate),
  });

  useEffect(() => {
    // this will make back button close this modal
    // instead of navigating back
    app.nav.showingBlockingModal();
  }, []);

  const token = app.memes.currentMeme.token;

  const tickets = app.tmg.tap?.tickets || 0;

  const { points } = app.ui.state.tapping;
  let pointsValue: string | undefined =
    points && points !== 'loading'
      ? largeNumberToLetter(Number(points))
      : points;

  // ---------------------------
  // loading - converting points

  const [isLoading, setIsLoading] = useState(true);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);

  useEffect(() => {
    const fps = 30;
    const maxFrames = 60;

    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => prevFrame + 1);
      setPercentageComplete(Math.floor((currentFrame / maxFrames) * 100));
    }, fps);

    if (currentFrame >= maxFrames) {
      setIsLoading(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentFrame]);
  // ---------------------------

  const onTapInviteButton = () => {
    if (isLoading) return;

    // open drawerTiktokInvite
    app.ui.drawer.show(
      {
        id: 'drawerTiktokInvite',
        skipBackdropClose: true,
        hideClose: isMobile() || isMobileEmulatedByBrowser(),
        opts: {},
      },
      true,
    );
  };

  const onTapPlayButton = () => {
    if (isLoading) return;

    app.ui.drawer.close();
    app.tmg.tap?.startGame('retry');
  };

  const onTapCloseButton = () => {
    app.ui.drawer.close();
  };

  const headerMessageLocalizationKey = useMemo(() => {
    const maxLocalizationKeyCount = 16;
    const randomLocalizationKeyIndex =
      Math.floor(Math.random() * maxLocalizationKeyCount) + 1;
    return 'tapgame_gameover_message_' + randomLocalizationKeyIndex;
  }, []);
  const headerMessage = t(headerMessageLocalizationKey);

  // Render final result
  const hasTickets = tickets > 0;
  const tappingMaxTickets = getTMGTappingMaxTickets(app.state);

  return (
    <div className="drawer-tiktok-gameover">
      {/* top header - empty */}
      <div className="drawer-tiktok-gameover-header"></div>

      {/* centered content - message and points */}
      <div className="drawer-tiktok-gameover-content">
        <div className="drawer-tiktok-gameover-header-message">
          {headerMessage}
        </div>

        {/* converting icon + loading bar */}
        {isLoading && <LoadingBar percentageComplete={percentageComplete} />}

        {/* points value + info label */}
        {!isLoading && (
          <div className="points-container">
            <div className="points-value">{pointsValue}</div>

            <div className="points-label-container">
              <div className="points-icon">
                <img src={assets.tiktok_points} />
              </div>
              <span className="points-label">{`${token?.ticker} ${t(
                'tapgame_gameover_points_earned',
              )}`}</span>
            </div>
          </div>
        )}
      </div>

      {/* bottom buttons  - invite & play again */}

      <div className="drawer-tiktok-gameover-buttons">
        <div className="btn gameover-button light" onClick={onTapInviteButton}>
          {`${t('tapgame_gameover_invite')} ${token?.ticker}`}

          <div className="gameover-button-icon">
            <img src={assets.tiktok_ticket} />
          </div>
        </div>

        {hasTickets && (
          <div className="btn gameover-button white" onClick={onTapPlayButton}>
            {t('tapgame_gameover_play')}
            <div className="gameover-button-icon">
              <img src={assets.tiktok_ticket} />
            </div>
            {tickets}/{tappingMaxTickets}
          </div>
        )}

        {!hasTickets && (
          <div className="btn gameover-button white" onClick={onTapCloseButton}>
            {t('tapgame_gameover_play_later')}
          </div>
        )}
      </div>
    </div>
  );
};

const LoadingBar = ({ percentageComplete }: { percentageComplete: number }) => {
  const { t } = useTranslation();
  const token = app.memes.currentMeme.token;

  return (
    <div className="drawer-tiktok-gameover-loading-container">
      {/* icon */}
      <div className="drawer-tiktok-gameover-loading-icon">
        <img src={assets.tiktok_tap_convert} />
      </div>
      {/* message */}
      <div className="drawer-tiktok-gameover-loading">
        {t('tapgame_gameover_convert', { ticker: token?.ticker })}
      </div>
      {/* progress bar */}
      <div className="progress-bar">
        <div className="progress-bar-bg" />
        <div
          className="progress-bar-value"
          style={{ width: `${percentageComplete}%` }}
        ></div>
      </div>
    </div>
  );
};
