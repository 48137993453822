import {
  getMyOffchainTokenPointAmount,
  getTTGKickback,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { BusinessController } from '../BusinessController';
import { SendEventFn } from '../EventController';
import { TMGFarming } from './TMGFarming';
import { TMGTapping } from './TMGTapping';

export enum TMGEvents {
  // General
  OnPointsUpdate = 'OnPointsUpdate',
  // Farming
  OnFarmingUpdate = 'OnFarmingUpdate',
  // Tapping
  OnTappingGameTick = 'OnTappingGameTick',
  OnTappingUpdate = 'OnTappingUpdate',
  OnTappingTap = 'OnTappingTap',
  OnTappingPlayingUpdate = 'OnTappingPlayingUpdate',
  OnTappingGameOver = 'OnTappingGameOver',
}

export class TokenMiniGamesController extends BusinessController<
  TMGEvents,
  undefined
> {
  private get tokenId() {
    return this.app.memes.currentMeme.token?.id;
  }

  public get points() {
    if (!this.tokenId) {
      return '0';
    }
    return getMyOffchainTokenPointAmount(this.app.state, this.tokenId);
  }

  public get myKickback() {
    if (!this.tokenId) {
      return 0;
    }
    return getTTGKickback(this.app.state, this.tokenId);
  }

  private farming = new TMGFarming(this.app);
  public get farm() {
    return this.farming.state;
  }

  private tapping = new TMGTapping(this.app);
  public get tap() {
    return this.tapping.state;
  }

  public isTapping() {
    if (!this.tap) {
      return false;
    }

    return this.tap.isPlaying;
  }

  public init = async () => {
    // No need to async
    Promise.all([
      this.tapping.init({ sendEvtProxy: this.sendEvents as SendEventFn }),
      this.farming.init({ sendEvtProxy: this.sendEvents as SendEventFn }),
    ]);
  };
}
