import './DrawerTiktokBuySell.scss';
import '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter.scss';
import { app } from '../../../../data/Controllers/AppController';
import { CurrencyType } from '../../../../replicant/features/tradingMeme/types';
import { TradingTokenFooterForm } from '../../TradingTokenPage/TradingTokenFooter/TradingTokenFooter';
import { useTranslation } from 'react-i18next';
import { isIOS } from '../../../../data/device';
import { Tabs, TabStyle } from '../../../shared/Tabs/Tabs';
import { assets } from '../../../../assets/assets';
import { useState } from 'react';

// Note:
// This drawer is used exclusively from TiktokPage, but references components and css classes from TradingTokenPage/TradingTokenFooter
// TradingTokenPage we use a baked in component

export const DrawerTiktokBuySell = () => {
  const { t } = useTranslation();
  const isBuy = app.memes.trading.tx?.txType.includes('buy');

  const [currencyType, setCurrencyType] = useState<CurrencyType>('tokens');

  const onTabClick = (tab: CurrencyType) => {
    setCurrencyType(tab);
  };

  const onClose = () => {
    app.ui.drawer.close();
  };

  if (!app.memes.currentMeme) {
    return null;
  }

  const { token } = app.memes.currentMeme;
  const titleKey = isBuy ? 'buysell_title_buy' : 'buysell_title_sell';
  const title = t(titleKey, { ticker: token?.ticker });

  return (
    <div className="drawer-tiktok-buysell">
      {/* header (title + custom close button) */}
      <div className="buysell-header">
        <div className="buysell-header-row">
          <div className="buysell-title">{title}</div>
          <div className="btn button-close-custom" onClick={onClose}>
            <img src={assets.button_arrow_down} />
          </div>
        </div>
      </div>

      <Tabs<CurrencyType>
        tabStyle={TabStyle.Rounded}
        height={32}
        initial={'tokens'}
        autoSelected={'tokens'}
        onTabClick={onTabClick}
        tabs={[
          {
            id: 'points',
            icon: assets.tab_icon_coin,
            name: t('buysell_tab_points'),
            component: (
              <div className="trading-token-footer-container">
                <TradingTokenFooterForm
                  footerMode={'normal'}
                  txType={isBuy ? 'buy' : 'sell'}
                  currencyType={currencyType}
                />
              </div>
            ),
          },
          {
            id: 'tokens',
            icon: assets.tab_icon_token,
            name: t('buysell_tab_tokens'),
            component: (
              <div className="trading-token-footer-container">
                <TradingTokenFooterForm
                  footerMode={'normal'}
                  txType={isBuy ? 'buy' : 'sell'}
                  currencyType={currencyType}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
