import './TradingTokenFooter.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  Currency,
  CurrencyType,
  TxType,
} from '../../../../replicant/features/tradingMeme/types';
import { getPct } from '../../../../data/utils';

import { assets } from '../../../../assets/assets';
import {
  formatPrice,
  largeIntegerToLetter,
  displayPointAmount,
  roundDecimals,
} from '../../../../replicant/utils/numbers';
import { TradingInputNumeric } from '../../TradingPage/TradingInputNumeric/TradingInputNumeric';
import { useEffect, useState } from 'react';
import { isIOS } from '../../../../data/device';
import { DottedSlider } from '../../../shared/DottedSlider/DottedSlider';
import { memePointsDisplayMultiplier } from '../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';
import { t } from 'i18next';
import { HighPrecision, HP } from '../../../../replicant/lib/HighPrecision';

function getCurrencyIcon(currencyType: CurrencyType, txType?: TxType) {
  let image =
    currencyType === 'tokens' ? assets.tab_icon_token : assets.tab_icon_coin;

  if (txType && txType === 'sell') {
    const token = app.memes.currentMeme.token;
    image = token?.image || '';
  }

  return image;
}

function getCurrencyName(currencyType: CurrencyType, txType?: TxType) {
  let name = currencyType === 'tokens' ? 'TON' : 'POINTS';

  if (txType && txType === 'sell') {
    const token = app.memes.currentMeme.token;
    name = '$' + token?.ticker || '';
  }

  return name;
}

// ============================================================================
// region TokenFooter
// note: this is used by TradingTokenPage and DrawerCreateTradingConfirm

interface FooterProps {
  footerMode: 'normal' | 'create';
}

export const TradingTokenFooter = ({ footerMode }: FooterProps) => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'OffchainTokenFooter',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  // -----------------------------------------------
  // render buy/sell footer component in create mode

  if (footerMode === 'create') {
    return (
      <div className={`trading-token-footer-container noTabs`}>
        <TradingTokenFooterForm
          footerMode={footerMode}
          txType="buy"
          currencyType={'tokens'} // todo carles review
        />
      </div>
    );
  }

  // -----------------------------------------------
  //  render buy/sell footer component in normal mode

  return (
    <div className="trading-token-footer-container">
      <div className="trading-token-footer-header sell-header">
        <TradingTokenFooterForm
          footerMode={'normal'}
          txType={'sell'}
          currencyType={'tokens'} // todo carles review
        />
      </div>
    </div>
  );
};

// ==============================================================================
// #region Form

interface PropsFooterForm {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currency?: Currency;
  currencyType: CurrencyType;
}

export const TradingTokenFooterForm = ({
  footerMode,
  txType,
  currency = 'coins',
  currencyType,
}: PropsFooterForm) => {
  useAppUpdates({
    id: 'OffchainTokenFooterForm',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(0);

  const { trading, currentMeme } = app.memes;

  const points = Number(app.tmg.points);

  const pointAmount = currentMeme.myTokenState?.pointAmount ?? 0;

  const maxPointAmount = pointAmount ? HP(pointAmount).toNumber() : 0;

  const useCoins = currency === 'coins';
  const maxAmount = trading.isBuy
    ? useCoins
      ? app.state.balance
      : points
    : maxPointAmount;

  const setSpendAmount = (spendAmount: HighPrecision) => {
    const percentage =
      maxAmount > 0 ? spendAmount.div(maxAmount).toNumber() : 0;

    setSliderValue(Math.max(0, Math.min(percentage, 1)));
  };

  useEffect(() => {
    const amount = trading.tx?.send ?? HP(0);
    setSpendAmount(amount);
  }, [
    trading.tx?.send,
    trading.isBuy,
    app.state.balance,
    points,
    pointAmount,
    txType,
  ]);

  console.log('TradingTokenFooter', { tx: app.memes.trading.tx });

  if (!app.memes.trading.tx) {
    return null;
  }

  const balance = useCoins ? app.state.balance : points;
  const prettyBalance = largeIntegerToLetter(balance);

  const onSliderChange = (percentage: number) => {
    setSliderValue(percentage);

    if (app.memes.trading.isBuy) {
      const maxAmount = useCoins ? app.state.balance : points;

      app.memes.trading.updateAmount(HP(Math.round(maxAmount * percentage)));
      return;
    }

    // selling
    const token = app.memes.currentMeme.myTokenState;
    if (!token) {
      app.memes.trading.updateAmount(HP(0));
      return;
    }

    if (percentage >= 1.0) {
      app.memes.trading.updateAmount(HP(token.pointAmount));
      return;
    }

    const maxPointAmount = HP(token.pointAmount).toNumber();
    const amount = HP(roundDecimals(maxPointAmount * percentage));
    const max = HP(token.pointAmount);

    if (amount.gt(max)) {
      app.memes.trading.updateAmount(max);
    } else {
      app.memes.trading.updateAmount(amount);
    }
  };

  // execute buy/sell -> close current drawer, refresh token and display condirmation drawer
  const onTapPurchase = () => {
    app.ui.drawer.close();
    app.memes.trading.goToTxConfirmation();
  };

  const isBuy = trading.tx?.txType === 'buy';
  const spendAmount = trading.tx?.send.toNumber() ?? 0;
  const noCoinsClass = app.state.balance <= 0 ? 'invisible squashed' : '';

  // const tickerName = currentMeme.token?.ticker ?? '#';

  const onInputUpdate = (value: number) => {
    if (trading.tx?.txType === 'buy') {
      trading.updateAmount(HP(value));
    } else {
      trading.updateAmount(HP(value / memePointsDisplayMultiplier));
    }

    // when changin input value, we must update slider bar too
    setSpendAmount(HP(value));
  };

  // ==============================================================================
  // #region Form Elms

  return (
    <div className="trading-token-footer-form-area">
      {/* header */}
      <div className="buysell-header">
        {/* balance + slippage */}
        <div className="buysell-header-row">
          <div className="buysell-header-balance">
            <div className="buysell-header-balance-label">
              {t('buysell_available_balance')}
            </div>
            <div className="buysell-header-balance-value">{prettyBalance}</div>
            <div className="buysell-header-balance-icon">
              <img src={getCurrencyIcon(currencyType, txType)} />
            </div>
          </div>
          <div className="buysell-header-slippage">{'Set max slippage'}</div>
        </div>

        {/* ticker or coin icon */}
        <div className={`trading-token-footer-form ${noCoinsClass}`}>
          {/* numeric input */}
          <TradingInputNumeric
            type={isBuy ? 'numeric' : 'decimal'}
            value={
              isBuy ? spendAmount : spendAmount * memePointsDisplayMultiplier
            }
            maxValue={
              isBuy ? maxAmount : maxAmount * memePointsDisplayMultiplier
            }
            icon={assets.button_x}
            pushContentUp={280}
            onAmountChange={onInputUpdate}
            integerOnly={app.memes.trading.isBuy}
            // onInputFocus={(focused: boolean) => setSelectedAmount(0)}
          />

          {/* ton | points */}
          <div className="currency-type">
            <div className="currency-type-label">
              {getCurrencyName(currencyType, txType)}
            </div>
            <div className="currency-type-icon">
              <img src={getCurrencyIcon(currencyType, txType)} />
            </div>
          </div>
        </div>
      </div>

      <DottedSlider
        initialValue={sliderValue}
        labels={['0%', '25%', '50%', '75%', '100%']}
        onSliderChange={onSliderChange}
      />

      <div className={`trading-token-footer-fees centered ${noCoinsClass}`}>
        <TradingTokenFooterFeeMessage currencyType={currencyType} />
      </div>

      {footerMode === 'normal' ? (
        <TradingTokenFooterButton
          footerMode={footerMode}
          txType={txType}
          currencyType={currencyType}
          onClick={onTapPurchase}
        />
      ) : (
        <TradingTokenFooterCreateButton
          noCoinsClass={noCoinsClass && 'no-coins'}
        />
      )}
    </div>
  );
};

// ==============================================================================
// #region Fee

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterFeeMessage = ({
  currencyType,
}: {
  currencyType?: CurrencyType;
}) => {
  const { t } = useTranslation();
  const fee = app.memes.trading.getTxFee();

  // get the fee pct relative to 100% with no decimal places
  const feePct = getPct(100 * fee.feePercentage, 100, 0);

  if (!currencyType) currencyType = 'points';

  return (
    <div className="trading-token-footer-fee-message-container">
      <div className="percent">~{feePct}</div>
      <div className="transaction-fee">
        {t('trading_token_footer_transaction_fee')}:
      </div>
      <div className="coin">
        <img src={getCurrencyIcon(currencyType)} />
      </div>
      <div className="value">{formatPrice(fee.feeAmount)}</div>
    </div>
  );
};

// ==============================================================================
// #region Button

export interface PropsFooterBuySellButton {
  footerMode: 'normal' | 'create';
  txType: TxType;
  currencyType: CurrencyType;
  onClick: () => void;
}

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterButton = ({
  txType,
  currencyType,
  onClick,
}: PropsFooterBuySellButton) => {
  const { t } = useTranslation();

  const { tx } = app.memes.trading;
  if (!tx) {
    return null;
  }

  const { receive, isValid } = tx;

  const tickerName = app.memes.currentMeme.token?.ticker ?? '#';

  const receiveNum = receive.toNumber();
  const amount = receive.gte(0)
    ? txType === 'buy'
      ? displayPointAmount(receiveNum, 4, true)
      : largeIntegerToLetter(receiveNum, 4)
    : '-';

  return (
    <div
      className={`btn btn-normal button-buy ${!isValid && 'disabled'}`}
      onClick={onClick}
    >
      <div className="content-buy">
        {txType.includes('buy') && (
          <>
            {/* <img src={assets.icon_arrow_buy} className="icon-arrow" /> */}
            <div className="label">{`${t('trade_token_button_buy')}`}</div>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: amount }}
            ></div>
            <div className="label">${tickerName}</div>

            <div className="icon">
              <img src={app.memes.currentMeme.token?.image} />
            </div>
          </>
        )}

        {txType === 'sell' && (
          <>
            {/* <img src={assets.icon_arrow_sell} className="icon-arrow" /> */}
            <div className="label">{`${t('trade_token_button_sell_for')}`}</div>

            <div className="value">{amount} </div>
            <div className="label">{getCurrencyName(currencyType)}</div>
            <div className="icon">
              <img src={getCurrencyIcon(currencyType)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// ==============================================================================
// #region CreateButton

interface CreateBtnProps {
  noCoinsClass?: string;
}

const TradingTokenFooterCreateButton = ({ noCoinsClass }: CreateBtnProps) => {
  const { t } = useTranslation();

  const { tx } = app.memes.trading;

  const [isLoading, setIsLoading] = useState(false);

  if (!tx) return null;

  const { receive, isValid, send } = tx;
  const isValidOrNotInvesting = isValid || send.eq(0);

  const createOffchainTokenConfirm = async () => {
    if (!isValidOrNotInvesting) {
      return;
    }
    setIsLoading(true);
    app.memes.factory.createOffchainToken().finally(() => {
      setIsLoading(false);
    });
  };

  const isListingAndInvesting = receive.gt(0);
  const btnLabelKey = isListingAndInvesting
    ? 'trade_token_button_list_invest'
    : 'trade_token_button_list';
  const btnLabel = t(btnLabelKey);
  const freeLabel = t('free');
  const disableBtn = !isValidOrNotInvesting || isLoading;
  const useFreeLabel = app.state.tokenCreationCredits > 0;
  // Update this line to use the ticker of the token being created
  const tickerName = app.memes.factory.newTokenForm.data?.ticker ?? '#';
  const pointAmount = displayPointAmount(receive.toNumber(), 4, true);

  return (
    <div
      className={`btn btn-normal button-buy ${
        disableBtn && 'disabled'
      } ${noCoinsClass}`}
      onClick={createOffchainTokenConfirm}
    >
      <div className="content-buy">
        <div className="label">
          {useFreeLabel && freeLabel} {btnLabel}
        </div>
        {isListingAndInvesting && (
          <>
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: pointAmount }}
            ></div>
            <div className="label">${tickerName}</div>
          </>
        )}
      </div>
    </div>
  );
};

// ==============================================================================
