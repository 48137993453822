import { createScheduledActions } from './createScheduledActions';
import { chatbotScheduledActions } from './features/chatbot/chatbot.scheduledActions';
import { gameScheduledActions } from './features/game/game.scheduledActions';
import { offchainMemeScheduledActions } from './features/tradingMeme/tradingMeme.scheduledActions';
import { friendsScheduledActions } from './features/friends/friends.scheduledActions';
import { onchainTxsScheduledActions } from './features/onchainTxs/onchainTxs.scheduledActions';

export default createScheduledActions({
  ...gameScheduledActions.schema,
  ...chatbotScheduledActions.schema,
  ...friendsScheduledActions.schema,
  ...offchainMemeScheduledActions.schema,
  ...onchainTxsScheduledActions.schema,
})({
  ...gameScheduledActions.actions,
  ...chatbotScheduledActions.actions,
  ...friendsScheduledActions.actions,
  ...offchainMemeScheduledActions.actions,
  ...onchainTxsScheduledActions.actions,
});
