import { Sha256 } from '@aws-crypto/sha256-js';
import { Dictionary, beginCell, Cell, Builder, Slice } from '@ton/core';
import { sha256_sync } from '@ton/crypto';
import { MetadataInput } from './types';

export type JettonDeploy = {
  $$type: 'JettonDeploy';
  queryId: bigint;
  metadata: Cell;
  nonce: Cell;
  signature: Slice;
};
export function storeJettonDeploy(src: JettonDeploy) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(389861951, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.metadata);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
  };
}

export type AdminCallTransferJetton = {
  $$type: 'AdminCallTransferJetton';
  queryId: bigint;
  nonce: Cell;
  signature: Slice;
  metadata: Cell | null;
};
export function storeAdminCallTransferJetton(src: AdminCallTransferJetton) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(260734632, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
    if (src.metadata !== null && src.metadata !== undefined) {
      b_0.storeBit(true).storeRef(src.metadata);
    } else {
      b_0.storeBit(false);
    }
  };
}

export type AdminCallSellJetton = {
  $$type: 'AdminCallSellJetton';
  queryId: bigint;
  nonce: Cell;
  signature: Slice;
};
export function storeAdminCallSellJetton(src: AdminCallSellJetton) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(260734633, 32);
    b_0.storeUint(src.queryId, 64);
    b_0.storeRef(src.nonce);
    b_0.storeRef(src.signature.asCell());
  };
}

export type Ping = {
  $$type: 'Ping';
  ping: string;
};
export function storePing(src: Ping) {
  return (builder: Builder) => {
    let b_0 = builder;
    b_0.storeUint(1267924010, 32);
    b_0.storeStringRefTail(src.ping);
  };
}

export const storeBuilders = {
  createJettonContract: storeJettonDeploy,
  buyToken: storeAdminCallTransferJetton,
  sellToken: storeAdminCallSellJetton,
  ping: storePing,
};

const ONCHAIN_CONTENT_PREFIX = 0x00;
const SNAKE_PREFIX = 0x00;
const CELL_MAX_SIZE_BYTES = Math.floor((1023 - 8) / 8);

const sha256 = (str: string) => {
  const sha = new Sha256();
  sha.update(str);
  return Buffer.from(sha.digestSync());
};

const toKey = (key: string) => {
  return BigInt(`0x${sha256(key).toString('hex')}`);
};

export function buildOnchainMetadata(data: MetadataInput): Cell {
  let dict = Dictionary.empty(
    Dictionary.Keys.BigUint(256),
    Dictionary.Values.Cell(),
  );

  // Store the on-chain metadata in the dictionary
  Object.entries(data).forEach(([key, value]) => {
    dict.set(toKey(key), makeSnakeCell(Buffer.from(value, 'utf8')));
  });

  return beginCell()
    .storeInt(ONCHAIN_CONTENT_PREFIX, 8)
    .storeDict(dict)
    .endCell();
}

export function makeSnakeCell(data: Buffer) {
  // Create a cell that package the data
  let chunks = bufferToChunks(data, CELL_MAX_SIZE_BYTES);

  const b = chunks.reduceRight((curCell, chunk, index) => {
    if (index === 0) {
      curCell.storeInt(SNAKE_PREFIX, 8);
    }
    curCell.storeBuffer(chunk);
    if (index > 0) {
      const cell = curCell.endCell();
      return beginCell().storeRef(cell);
    } else {
      return curCell;
    }
  }, beginCell());
  return b.endCell();
}

function bufferToChunks(buff: Buffer, chunkSize: number) {
  let chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.slice(0, chunkSize));
    buff = buff.slice(chunkSize);
  }
  return chunks;
}

export function stringToBigInt(input: string): bigint {
  // Hash the string using SHA-256
  const hash = sha256_sync(input);

  // Convert the hash to a bigint
  return BigInt('0x' + hash.toString('hex'));
}
