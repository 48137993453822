import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useState } from 'react';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { getTransactionLabel, msToAgo } from '../../../../data/utils';
import { TradingToken } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingTx } from '../../../../replicant/features/tradingMeme/tradingMeme.schema';
import {
  displayPointAmount,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { ProgressBar } from '../../../shared/ProgressBar/ProgressBar';
import { FeedMemeFooter } from '../../TiktokPage/TiktokFeedMeme/FeedMemeFooter';
import { TradingChart } from '../../TradingTokenPage/Charts/TradingChart';
import { MemeDetailsSocialBtn } from './MemeDetailsSocialBtn';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { CurrencyType } from '../../../../replicant/features/tradingMeme/types';
import { getCoinIconReactImg } from '../../../../data/utils';
import { isMobileEmulatedByBrowser } from '../../../../data/device';

// -----------------------------------------------------------------------------
// These methods are used in order to set special responsive dimensions
// for MemeDetailsPage only

function getGraphHeightRelativeToScreen() {
  const d = 0;

  const isEmu = isMobileEmulatedByBrowser();
  const SE = isEmu ? 600 : 667;
  const Mini = isEmu ? 680 : 780;

  if (window.innerHeight <= SE) {
    // console.warn('>>> SE - 600 - 80');
    return 80; // SE
  }
  if (window.innerHeight <= Mini) {
    // console.warn('>>> Mini - 680 - 130');
    return 130; // Mini
  }

  // todo: this should be relative to the size of the screen. but what exactly?
  // console.warn('>>> XR - ... - 220');
  return 220; // XR
}

function getSectionHeightRelativeToScreen(margin: number = 0) {
  var style = getComputedStyle(document.body);
  const prop = style.getPropertyValue('--safe-height');

  let h = '100svh';
  if (prop) h = `calc(${prop} - 28px)`; // mobile has an extra of 28px

  return `calc(${h} - ${margin}px - 274px)`;
}

// -----------------------------------------------------------------------------
// #region MarketStats

export const MemeDetailsMarketStats = () => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const { token } = app.memes.currentMeme;

  const graphHeight = getGraphHeightRelativeToScreen();
  // console.warn('>>> graph height', window.innerHeight, '->', graphHeight);

  const sectionHeight = getSectionHeightRelativeToScreen(16);
  // console.warn(
  //   '>>> section height',
  //   getComputedStyle(document.body).getPropertyValue('--safe-height') || 0,
  //   '->',
  //   sectionHeight,
  // );

  return (
    <div
      className="meme-details-section marketStats"
      style={{ height: sectionHeight }}
    >
      <TradingChart tokenState={token} height={graphHeight} />
      <TokenValues token={token} />
      <ProgressArea token={token} />
    </div>
  );
};

const TokenValues = ({ token }: { token: TradingToken }) => {
  const { marketCap } = token.overview;

  return (
    <div className="item-mcap">
      <div className="item-mcap-section">
        <div className="item-mcap-value">
          {largeIntegerToLetter(HP(marketCap).toNumber())}
        </div>
        <div className="item-mcap-label">
          {t('market_stats_tab_meme_points_owned', { ticker: token.ticker })}
        </div>
      </div>
      <div className="item-mcap-separator"></div>
      <div className="item-mcap-section">
        <div className="item-mcap-value">
          {largeIntegerToLetter(HP(marketCap).toNumber())}
        </div>
        <div className="item-mcap-label">
          {t('market_stats_tab_meme_token_owned', { ticker: token.ticker })}
        </div>
      </div>
    </div>
  );
};

const ProgressArea = ({ token }: { token: TradingToken }) => {
  const { marketCap } = token.overview;
  const [completed, setCompleted] = useState(false);

  // progress completed state

  const onClaim = () => {
    // todo brice: Claim points
  };
  console.log('getCoinIconReactImg', getCoinIconReactImg());
  if (completed) {
    return (
      <div className="item-progress-area">
        {/* one line message */}
        <div
          className="item-progress-label grey w80 s13"
          onClick={() => setCompleted(!completed)}
        >
          {t('market_stats_tab_flavor_text_earn_points')}
        </div>

        {/* claim button */}
        <div className="btn btn-normal btn-claim" onClick={onClaim}>
          <Trans
            i18nKey="market_stats_tab_claim_button"
            components={{
              bold: <strong />,
              coinIcon: getCoinIconReactImg(),
            }}
            values={{
              tokenAmount: largeIntegerToLetter(HP(marketCap).toNumber()),
              pointAmount: 1234,
            }}
          />
        </div>

        {/* buy/sell buttons */}
        <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
      </div>
    );
  }

  // progress bar in curse
  const graduateAmount = 12345678; // TODO: get real graduate amount number. Should be $USD value of 1000 TON. Should probably get value from ton controller.
  const dexProgressPercent = '55'; // TODO: get real progress number

  return (
    <div className="item-progress-area">
      <div className="item-progress-title">
        <div className="item-progress-label grey s13">
          {t('market_stats_tab_progressbar_text')}
        </div>
        <div className="item-progress-label flavor">
          {/* <div className="item-progress-label blue bold s13">{dexListingProgress}</div>
          <div className="item-progress-label s13">{'to'}</div>
          <div className="item-progress-label bold s13">{'Dex Listing'}</div> */}
          <Trans
            i18nKey="market_stats_tab_dex_listing_text"
            components={{
              bold: <strong />,
              em: <em />,
            }}
            values={{
              dexProgressPercent,
            }}
          />
        </div>
      </div>

      <ProgressBar color="#0098ea" height={10} progress={50} />

      <div
        className="item-progress-footer"
        onClick={() => setCompleted(!completed)}
      >
        <div className="item-progress-label flavor">
          <Trans
            i18nKey="market_stats_tab_flavor_text_graduates"
            components={{
              bold: <strong />,
              em: <em />,
            }}
            values={{
              graduateAmount: largeIntegerToLetter(graduateAmount),
            }}
          />
        </div>
      </div>

      {/* buy/sell buttons */}
      <FeedMemeFooter onTapPurchaseButton={app.ui.onTapPurchaseButton} />
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region About

export const MemeDetailsAbout = () => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const { description, telegramChannelLink, telegramChatLink, twitterLink } =
    app.memes.currentMeme.token.description;

  const sectionHeight = getSectionHeightRelativeToScreen();

  const { token } = app.memes.currentMeme;

  return (
    <div
      className="meme-details-section about"
      style={{ height: sectionHeight }}
    >
      <div className="about-section-scroller">
        {/* description */}
        <div className="about-section">
          <div className="about-section-title">
            {t('about_tab_user_description_title')}
          </div>
          <div className="about-section-text">
            {
              'Lorem ipsum dolor alea jacta est Lorem ipsum dolor alea jacta est Lorem ipsum dolor alea jacta est Lorem ipsum dolor alea jacta est'
            }
          </div>
        </div>

        {/* social */}
        <div className="about-section">
          <div className="about-section-title">
            {t('about_tab_socials_title')}
          </div>
          <div className="about-social-buttons">
            <MemeDetailsSocialBtn
              className="channel"
              icon={assets.paperplane}
              title={t('trading_token_tabs_content_channel')}
              url={telegramChannelLink}
            />
            <MemeDetailsSocialBtn
              className="group"
              icon={assets.paperplane}
              title={t('trading_token_tabs_content_chat')}
              url={telegramChatLink}
            />
            <MemeDetailsSocialBtn
              className="channel"
              icon={assets.paperplane}
              title={t('trading_token_tabs_content_twitter')}
              url={twitterLink}
            />
          </div>
        </div>

        {/* statistics */}
        <div className="about-section">
          <div className="about-section-title">
            {t('about_tab_statistics_title')}
          </div>
          <StatRow
            icon={assets.stat_volume}
            title={t('about_tab_list_24hr_volume')}
            value={'$12.1M'}
          />
          <StatRow
            icon={assets.stat_liq}
            title={t('about_tab_list_liquidity')}
            value={'$45K'}
          />
          <StatRow
            icon={assets.stat_txns}
            title={t('about_tab_list_transactions')}
            value={'152k'}
          />
          <StatRow
            icon={assets.stat_contract}
            title={t('about_tab_list_pre_dex_contract')}
            value={'0xsad654...2135'}
          />
          <StatRow
            icon={assets.stat_contract}
            title={t('about_tab_list_dex_contract')}
            value={'0xhap765...3246'}
          />
          <StatRow
            icon={assets.stat_deployer}
            title={t('about_tab_list_deployer')}
            value={token.creatorName}
            auth={token.creatorImage}
          />
          <StatRow
            icon={assets.stat_buys}
            title={t('about_tab_list_buys')}
            value={'43.9%'}
          />
          <StatRow
            icon={assets.stat_sells}
            title={t('about_tab_list_sells')}
            value={'56/1%'}
          />
          <StatRow
            icon={assets.stat_holders}
            title={t('about_tab_list_holders')}
            value={'505.088'}
          />
        </div>
      </div>
    </div>
  );
};

const StatRow = ({
  icon,
  title,
  value,
  auth,
}: {
  icon: string;
  title: string;
  value: string;
  auth?: string;
}) => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  return (
    <div className="about-stats-row">
      <div className="about-stats-row-content">
        <img src={icon} />
        <div className="label grey">{title}</div>
      </div>
      <div className="about-stats-row-content">
        {auth && (
          <div className="creator-image">
            <img src={auth} />
          </div>
        )}
        <div className="label">{value}</div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Transactions

// -------------------------------------------
// Transaction List

export const MemeDetailsTransactions = () => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const { token } = app.memes.currentMeme;

  const transactions = token.transactions.slice().reverse() || [];

  const sectionHeight = getSectionHeightRelativeToScreen();

  const [currencyType, setCurrencyType] = useState<CurrencyType>('tokens');
  const onCurrencyFilterClick = (tab: CurrencyType) => {
    setCurrencyType(tab);
  };

  // todo(Brice/Cai): filter transaction list by currency type

  return (
    <div
      className="meme-details-section transactions"
      style={{ height: sectionHeight }}
    >
      <TransactionCurrencyFilter onTapCurrencyToggle={onCurrencyFilterClick} />

      <div className="meme-details-transactions-list">
        {transactions.map((tx, i) => (
          <MemeDetailsTransactionItem
            key={'tx_' + tx.createdAt + `_${i}`}
            tx={tx}
          />
        ))}
      </div>
    </div>
  );
};

const TransactionCurrencyFilter = ({
  onTapCurrencyToggle,
}: {
  onTapCurrencyToggle: (type: CurrencyType) => void;
}) => {
  const [currencyType, setCurrencyType] = useState<CurrencyType>('tokens');

  const onTap = (type: CurrencyType) => {
    setCurrencyType(type);
    onTapCurrencyToggle(type);
  };

  return (
    <div className="transactions-currency-filter">
      {/* filter title */}
      <div className="transactions-currency-filter-title">
        {t('transaction_tab_filter_transaction_title')}
      </div>

      {/* filter toggle buttons */}
      <div className="transactions-currency-toggle-buttons">
        {/* left - points */}
        <div
          className={`btn transactions-currency-toggle-button left ${
            currencyType === 'points' ? 'selected' : ''
          }`}
          onClick={() => onTap('points')}
        >
          <img src={assets.tab_icon_coin} />
          <div className="transactions-currency-toggle-label">
            {t('transaction_tab_filter_button_points')}
          </div>
        </div>
        {/* right - tokens */}
        <div
          className={`btn transactions-currency-toggle-button right ${
            currencyType === 'tokens' ? 'selected' : ''
          }`}
          onClick={() => onTap('tokens')}
        >
          <img src={assets.tab_icon_token} />
          <div className="transactions-currency-toggle-label">
            {t('transaction_tab_filter_button_tokens')}
          </div>
        </div>
      </div>
    </div>
  );
};

// -------------------------------------------
// Transaction Item

interface PropsTransactionItem {
  tx: TradingTx;
  onClick?: (userId: string) => void;
}

export const MemeDetailsTransactionItem = ({
  tx,
  onClick,
}: PropsTransactionItem) => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const pointAmount = tx.pointAmount;
  const txPrice = tx.currencyAmount;
  const txCurrency = tx.currency;
  const timeAgo = app.now() - tx.createdAt;

  const tickerName = app.memes.currentMeme.token.ticker;

  // in old format the transaction did not have a user name and photo
  // only use the transaction user name and photo if using new transaction format
  // otherwise use the creator name/photo
  const isNewTxFormat = !!tx.userName;
  const userName = isNewTxFormat
    ? tx.userName
    : app.memes.currentMeme.token.creatorName;
  const userImage = isNewTxFormat
    ? resolveProfilePicture(tx.userId, tx.userImage)
    : app.memes.currentMeme.token.creatorImage;

  const goToProfile = () => {
    app.nav.goToProfile(tx.userId);
    onClick && onClick(tx.userId);
  };

  const pointAmountStr = displayPointAmount(
    HP(pointAmount).toNumber(),
    4,
    true,
  );

  const txLabel = getTransactionLabel(tx);

  return (
    <div className="meme-details-transaction-item" onClick={goToProfile}>
      <div className="transaction-left">
        <div className="image">
          <img src={userImage} />
        </div>

        <div className="info">
          <div className="name">{userName}</div>
          <div className="bottom">
            <div className={`type ${tx.txType.toLowerCase()}`}>{txLabel}</div>
            {txCurrency !== 'points' && txCurrency !== 'gift' && (
              <>
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="value">
                  {largeIntegerToLetter(HP(txPrice).toNumber())}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="transaction-right">
        <div className="value">
          <div
            className="value-label"
            dangerouslySetInnerHTML={{ __html: pointAmountStr }}
          />
          <div className="value-label">${tickerName}</div>
        </div>

        <div className="time">{msToAgo(timeAgo)}</div>
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// #region Holders

// interface PropsHolder {
//   data: TradingTokenHolder;
// }

// export const TradingTokenHoldersItem = ({ data }: PropsHolder) => {
//   const tickerName = app.memes.currentMeme.token?.ticker ?? '#';

//   const goToProfile = () => {
//     app.nav.goToProfile(data.userId);
//   };

//   const pointAmount = displayPointAmount(
//     data.offchainTokenCount.toNumber(),
//     4,
//     false,
//   );

//   return (
//     <div className="meme-details-holder-item" onClick={goToProfile}>
//       <div className="holder-left">
//         <div className="image">
//           <img src={data.image} />
//         </div>

//         <div className="info">
//           <div className="top">
//             {data.type === 'creator' && (
//               <div className="creator">{`[${data.type}]`}</div>
//             )}
//             <div className="name">{data.name}</div>
//           </div>
//           <div className="offchainTokens">{`${pointAmount} ${tickerName}`}</div>
//         </div>
//       </div>

//       <div className="holder-right">
//         <div className="offchainTokens">~ {data.offchainTokenMarketShare}</div>
//       </div>
//     </div>
//   );
// };

// -----------------------------------------------------------------------------
// #region PriceArea

// interface MemeDetailsPriceAreaProps {
//   showChart?: boolean;
// }

// export const MemeDetailsPriceArea = ({
//   showChart,
// }: MemeDetailsPriceAreaProps) => {
//   useAppUpdates({
//     id: 'MemeDetailsPriceArea',
//     listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
//   });

//   useAppUpdates({
//     id: 'OnFooterExpanded',
//     listener: app.ui.attachEventListener(UIEvents.OnFooterExpanded),
//   });

//   const curvePrice = getCurvePrice(
//     highPrecision(app.memes.currentMeme.token?.supply ?? 0),
//   );
//   return (
//     <div
//       className="trading-price-area-container"
//       style={{ paddingBottom: app.ui.footerExpanded ? '270px' : '35px' }}
//     >
//       <div className="trading-price-area">
//         <div className="price-area-left">
//           <div className="current">
//             {/* todo(Cai): hook up timer */}
//             <div className="label">{t('trading_token_current_price')}</div>
//             <div className="price">
//               <div className="icon">
//                 <img src={assets.coin} />
//               </div>
//               <div className="value">
//                 {curvePrice ? displayPrice(curvePrice.toNumber(), 4) : '-'}
//               </div>
//               {/* <div className="percent green">{`${formatPrice(
//                 app.memes.currentMeme.token?.changePerHour || 0,
//               )}%`}</div> */}
//             </div>
//           </div>

//           {/* <div className="change">
//             <div className="label">{'1h Chg%'}</div>
//             <div className="value green">{`${formatPrice(
//               data?.changePerHour || 0,
//             )}%`}</div>
//           </div> */}
//         </div>

//         <div className="price-area-right">
//           <div
//             className="btn image"
//             onClick={() => {
//               app.ui.drawer.show({
//                 id: 'drawerTradingComingSoon',
//                 hideClose: true,
//               });
//             }}
//           >
//             <img src={assets.trading_price_area_icon} />
//           </div>
//         </div>
//       </div>

//       {/* trading chart */}
//       {showChart && app.memes.currentMeme.token && (
//         <TradingChart tokenState={app.memes.currentMeme.token} height={250} />
//       )}
//     </div>
//   );
// };

// -----------------------------------------------------------------------------
