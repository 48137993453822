import './DrawerTradingTransactionConfirm.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import {
  TradingTokenFooterButton,
  TradingTokenFooterFeeMessage,
} from '../TradingTokenFooter/TradingTokenFooter';
import {
  formatPrice,
  displayPointAmount,
} from '../../../../replicant/utils/numbers';

const TransactionRow = (opts: {
  title?: string;
  icon?: string;
  value: string;
}) => {
  const { title, icon, value } = opts;
  return (
    <div className="row">
      <div className="left">{title}</div>
      <div className="right">
        {icon && <img className="icon" src={icon} />}
        <div
          className="value"
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      </div>
    </div>
  );
};

export const DrawerTradingTransactionConfirm = () => {
  const { t } = useTranslation();

  const { trading, currentMeme } = app.memes;

  const offchainToken = currentMeme.token;

  if (!trading.txSummary || !offchainToken) {
    // @TODO: If this happens we actually have to close the drawer
    return null;
  }

  const { shareOfTheSupply, sending, receiving, txType } = trading.txSummary;

  const isBuying = txType.includes('buy');

  let sendRowTickerName;
  if (isBuying) {
    sendRowTickerName = t('trading_transaction_coins');
  } else {
    sendRowTickerName = offchainToken.ticker;
  }

  let sendRowIcon;
  if (isBuying) {
    sendRowIcon = assets.coin;
  } else {
    sendRowIcon = offchainToken.image;
  }

  let sendRowValue;
  if (isBuying) {
    sendRowValue = formatPrice(Number(sending));
  } else {
    sendRowValue = displayPointAmount(Number(sending), 8, true, true);
  }

  const sendRow = {
    title: t('trading_transaction_confirm_send', {
      tokenName: sendRowTickerName,
    }),
    icon: sendRowIcon,
    value: sendRowValue,
  };

  const receiveRowTokenName = isBuying
    ? offchainToken.ticker
    : t('trading_transaction_coins');
  const receiveRow = {
    title: t('trading_transaction_confirm_receive', {
      tokenName: receiveRowTokenName,
    }),
    icon: isBuying ? offchainToken.image : assets.coin,
    value: isBuying
      ? displayPointAmount(Number(receiving), 8, true, true)
      : formatPrice(Number(receiving)),
  };

  return (
    <div className="drawer-trading-transaction-confirm">
      <div className="drawer-trading-transaction-confirm-header">
        <div className="title">{t('trade_token_confirm_transaction')}</div>

        <div className="rows">
          <TransactionRow
            title={'Name'}
            icon={undefined}
            value={offchainToken.name}
          />
          <TransactionRow
            title={sendRow.title}
            icon={sendRow.icon}
            value={sendRow.value}
          />
          <TransactionRow
            title={receiveRow.title}
            icon={receiveRow.icon}
            value={receiveRow.value}
          />
          <TransactionRow
            title={t('trading_transaction_share_of_supply')}
            icon={undefined}
            value={shareOfTheSupply}
          />
        </div>

        <div className="bottom">
          <TradingTokenFooterFeeMessage />

          <TradingTokenFooterButton
            footerMode={'normal'}
            txType={txType}
            currencyType={'tokens'} // todo carles review
            onClick={() => {
              // execute buy/sell action
              trading.submitTransaction();
              app.ui.drawer.close();
            }}
          />
        </div>
      </div>
    </div>
  );
};
