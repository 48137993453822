import { AppController, Route } from './AppController';
import { Optional } from '../types';
import { isTiktokEnabled } from '../utils';
import { ElementUIState, ProfileTab } from './UIController/UITypes';
import { EventListener } from '../EventListener';
import { TradingCategories } from '../../components/pages/TradingPage/TradingPage';
import { SearchFilters, UserMemeFilters } from './Memes/types';
import { tests } from '../../replicant/ruleset';
import { MemeOverviewSourceName } from './Memes/MemesController';
import { BusinessController } from './BusinessController';

export enum NavEvents {
  OnNavigate = 'OnNavigate',
  // This event happens on click before navigating; Use for custom back button press
  OnBackClick = 'OnBackClick',
}

interface NavOpts<T> {
  // Clear the history if set to true
  wipeCrumbs?: boolean;
  // Pushes a route before itself
  pushParent?: T;
  // This is used for pages which load items based on a param like user profile, trading item
  routeParam?: string;
}

// @todo: remove (POST SEASON 2 MIGRATION)
// const nonTikTokRoutes: Route[] = [
//   'LeaderboardDrawer',
//   'JoinTeam',
//   'TeamPage',
//   'Shop',
//   'Toast',
//   'LeaguePage',
//   'MinePage',
// ];

export class NavController extends BusinessController<NavEvents> {
  private homepage: Route | 'Clicker' = 'Clicker';

  private history: (Route | 'Clicker' | 'Modal')[] = [this.homepage];
  private historyParams: Record<number, string> = {};
  private nextOpts?: any;

  private suspended = true;

  get currentRoute() {
    return this.history[this.history.length - 1];
  }

  get prevRoute() {
    return this.history[this.history.length - 2];
  }

  get isClicker() {
    return this.currentRoute === 'Clicker';
  }

  public init = async () => {
    if (this.ready) {
      console.warn(`Cannot init 'nav' more than once.`);
      return;
    }
    this.app.ui.drawer.onAnyModalClose(this.handleModalReset);
    this.homepage = 'TiktokPage';
    // @todo: remove (POST SEASON 2 MIGRATION)
    // this.history = homepage === 'Clicker' ? [this.homepage] : [];
    this.history = [];
    this.historyParams = {};
    this.nextOpts = undefined;
    this.suspended = false;
    this.handleTGBackBtn();

    this.onInitComplete();
  };

  private handleModalReset = () => {
    // If a modal is closed via UI make sure to reset the nav state
    if (this.currentRoute === 'Modal') {
      this.history.pop();
    }
  };

  private closeKeyboard = () => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input) => input.blur());
  };

  private closePages = () => {
    // Hide all view components
    Object.values(this.app.views).forEach((component) => component.hide());
    // Show Telegram close button
    Telegram.WebApp.BackButton.hide();
  };

  private handleTGBackBtn = () => {
    if (this.currentRoute === this.homepage) {
      if (this.prevRoute === undefined || this.prevRoute === this.homepage) {
        Telegram.WebApp.BackButton.hide();
      }
    } else {
      Telegram.WebApp.BackButton.show();
    }
  };

  private navigate = (route: Route | 'Clicker', prop?: string) => {
    // We don't have navigation back from clicker, so any time we go to clicker we wipe history
    if (route === 'Clicker') {
      this.history = ['Clicker'];
      this.closePages();

      this.sendEvents(NavEvents.OnNavigate);
      return;
    }

    // Show black overlay
    this.app.ui.setClickerUIState({ outOfViewOverlay: ElementUIState.Normal });

    // current page = route when navigating back
    if (this.currentRoute !== route) {
      // Hide current view
      const view = this.getView(this.currentRoute);
      if (view) {
        this.app.views[view].hide();
      }
      // Just before we add the new route, if we should wipe, set back to default
      if (this.nextOpts?.wipeCrumbs) {
        this.history = ['Clicker'];
      }
      if (this.nextOpts?.pushParent) {
        this.history.push(this.nextOpts.pushParent);
      }
      this.history.push(route);
      if (prop) {
        this.historyParams[this.history.length] = prop;
      }
    }

    this.sendEvents(NavEvents.OnNavigate);

    this.handleTGBackBtn();

    // return the current view so we can do `setData`, etc
    return this.app.views[route].show(this.nextOpts);
  };

  /**
   * Use this function to navigate to a specific view; use (optional) `opts.wipeCrumbs` to reset history
   * @param route view name registered in AppController
   * @param opts view specific opts passed during view.show()
   * @returns
   */
  goTo = (
    route: Route,
    opts: any & NavOpts<Route> = undefined, // @TODO: Fix types
  ) => {
    if (this.suspended || route === this.currentRoute) {
      return;
    }

    // @todo: remove (POST SEASON 2 MIGRATION)
    // if (
    //   nonTikTokRoutes.includes(route)
    // ) {
    //   // ignore navigation
    //   return;
    // }

    // If we are navigating from within a modal, remove the modal from history
    if (this.currentRoute === 'Modal') {
      this.history.pop();
    }

    this.nextOpts = opts;

    return this.navigate(route, opts?.routeParam);
  };

  /**
   * This function is called on the back button; removes current route from history
   * and navigate to new current route
   * @returns
   */
  back = async () => {
    if (this.suspended) {
      return;
    }
    // whenever we tap on back button, make sure to close any active input/keyboard
    this.closeKeyboard();

    // Alaways go back to clicker
    if (this.history.length <= 1) {
      return;
    }

    this.sendEvents(NavEvents.OnBackClick);

    // Remove current route
    const prevRoute = this.history.pop();
    if (this.currentRoute === 'Clicker') {
      this.app.ui.setClickerUIState({
        outOfViewOverlay: ElementUIState.Hidden,
      });
    }

    if (this.currentRoute === 'TiktokPage') {
      const hasTapGameStarted = this.app.tmg.isTapping();
      if (hasTapGameStarted) {
        // console.warn('>>> Telegram BACK during tapgame -> resetGame');
        this.app.tmg.tap?.resetGame();
      }
    }

    if (this.prevRoute === 'TiktokPage') {
      this.app.memes.setCurrent(
        { filter: 'Hot' },
        { sourceCategory: 'navigation', sourceName: 'back' },
      );
      // this.app.trading.setListingCategory('Hot')
    }

    this.handleTGBackBtn();

    if (prevRoute === 'Modal') {
      this.app.ui.drawer.close();
      return;
    }

    const routeParam = this.historyParams[this.history.length];
    const view = this.getView(prevRoute);

    if (routeParam) {
      delete this.historyParams[this.history.length];
      await this.setupRouteWithParam(routeParam);
    }

    if (view) {
      this.app.views[view].hide();
    }
    // Go to new current route
    if (this.currentRoute !== 'Modal') {
      this.navigate(this.currentRoute);
    }
  };

  /**
   * This function has been introduced so we can handle different home pages; Initially we had only clicker;
   * We now have a version without the clicker as a page and want Tiktok to be the home page;
   * @note This function should make using `goToHomePage()` obsolete.
   */
  public goToHomePage = async () => {
    if (this.homepage === 'Clicker') {
      this.app.ui.setClickerUIState({
        outOfViewOverlay: ElementUIState.Hidden,
      });
      return this.navigate('Clicker');
    }

    // Show black overlay in clicker while we wait a frame before navigating
    this.app.ui.setClickerUIState({ outOfViewOverlay: ElementUIState.Normal });
    // Wait a frame because we need clicker to load firsst so the page css doesnt get screwed up
    await this.app.memes.isReady;
    return this.goToTiktokFeed();
  };

  /**
   * This function takes care of re applying page props when navigating back. It returns
   * false to prevent the default back navigation behaviour.
   * Extend this function if a new page is introduced that requires custom props
   * @param param
   * @returns false if the navigation is handled by
   */
  private setupRouteWithParam = async (param?: string) => {
    if (!param || this.suspended) {
      return;
    }
    this.suspended = true;
    switch (this.currentRoute) {
      case 'ProfilePage':
        await this.app.profile.setProfile(param);
        break;
      case 'TradingTokenPage':
        await this.app.memes.setCurrent({ tokenId: param });
        // await this.app.trading.setOffchainToken(param, true);
        break;
    }
    this.suspended = false;
  };

  /**
   * Use this function when showing a modal which intereferes with navigation. When
   * you need the back button to close the modal and go back to page below modal.
   *
   */
  showingBlockingModal = () => {
    if (this.currentRoute !== 'Modal') {
      this.history.push('Modal');
    }
    this.handleTGBackBtn();
  };

  private goToAsyncPage = async (
    route: Route,
    pageSetupFn: () => Promise<string | undefined>,
  ) => {
    if (this.suspended) {
      return;
    }
    this.suspended = true;
    const routeParam = await pageSetupFn();
    this.suspended = false;
    if (routeParam) {
      this.goTo(route, { routeParam });
    }
  };

  /**
   * Use this function to navigate to a profile; It takes care of waiting for the ProfileController
   * to be ready before it shows the page while suspending any navigation until this is completed
   */
  goToProfile = async (
    userId = this.app.state.id,
    opts?: { tab?: ProfileTab },
  ) => {
    this.goToAsyncPage('ProfilePage', async () => {
      if (opts?.tab) {
        this.app.ui.setProfileState({ ownedOrCreatedOrFarming: opts?.tab });
      }
      await this.app.profile.setProfile(userId);
      return userId;
    });
  };

  /**
   * Use this function to navigate to a profile; It takes care of waiting for the ProfileController
   * to be ready before it shows the page while suspending any navigation until this is completed
   */
  goToTradingToken = async (tokenId: string) => {
    this.goToAsyncPage('TradingTokenPage', async () => {
      await this.app.memes.setCurrent({ tokenId });
      return tokenId;
      // this.app.trading.setOffchainToken(tokenId, true),
    });
  };

  goToTiktokReferredMeme = async (tokenId: string) => {
    // if tiktok test is not enabled, navigate to TradingTokenPage instead
    if (!isTiktokEnabled()) {
      this.goToTradingToken(tokenId);
      return;
    }

    this.goToAsyncPage('TiktokPage', async () => {
      await this.app.memes.setCurrent(
        { tokenId, filter: 'Hot' },
        { sourceCategory: 'share', sourceName: 'share' },
      );

      this.app.memes.setReferredMeme(tokenId);

      return tokenId;
    });
  };

  goToTiktokFeed = async (
    tokenId = this.app.memes.currentMeme.listing?.offchainTokenId ??
      this.app.memes.market.firstItem?.offchainTokenId,
    cat?: TradingCategories,
    sourceName?: MemeOverviewSourceName,
  ) => {
    // if tiktok test is not enabled, navigate to TradingTokenPage instead
    if (!isTiktokEnabled()) {
      this.goToTradingToken(tokenId);
      return;
    }

    this.goToAsyncPage('TiktokPage', async () => {
      // if (cat) {
      //   // await this.app.trading.setListingCategory(cat);
      //   // this.app.memes.market.setFilter(cat as SearchFilters);
      // }
      // if (tokenId) {
      //   await this.app.trading.setOffchainToken(tokenId, true);
      // }

      const filter = cat ? (cat as SearchFilters) : undefined;

      await this.app.memes.setCurrent(
        { tokenId, filter },
        {
          sourceCategory: 'navigation',
          sourceName: sourceName || filter || 'feed',
        },
      );

      const hasToken = Boolean(this.app.memes.currentMeme.token);
      if (!hasToken) {
        // It was designed to not allow navigation from the setup function
        // but in the case we want to handle custom case navigation here, so set suspended to false to be allowed to navigate
        this.suspended = false;
        this.goTo('TradingCreatePage');
        return undefined;
      }
      return tokenId;
    });
  };

  goToUserMemeToken = async (
    filter: UserMemeFilters,
    tokenId: string,
    sourceName?: MemeOverviewSourceName,
  ) => {
    this.goToAsyncPage('TiktokPage', async () => {
      // Pre setup the filter so we can navigate to the token afterwards
      if (filter) {
        const isNotOwnProfile = !this.app.profile.current?.isSelf;
        const isUserFilter = this.app.memes.userMemes.getIsMyFilter(filter);

        // We control the update of our own memes separate, only update userMemes for other people's profile
        if (isNotOwnProfile && isUserFilter) {
          await this.app.memes.userMemes
            .getList(filter)
            .requestUpdate('refresh');
        }
      }

      await this.app.memes.setCurrent(
        { filter, tokenId },
        { sourceCategory: 'navigation', sourceName: sourceName || filter },
      );
      return tokenId;
    });
  };

  goToTiktokSearchPage = async () => {
    this.goTo('TiktokSearchPage');
  };

  /**
   * Call this function when the app is ready passing in the query params related to deep linking;
   * By default it navigates to a route received if valid. But also has a hook for custom deep linking
   * when values are need to be set before navigation, etc.
   * @param maybeRoute value extracted from the query params when loading the app
   * @param dlOpts value extracted from the query params when loading the app
   * @returns void
   */
  deepLink = async (maybeRoute?: string, dlOpts?: string) => {
    if (!maybeRoute) {
      return false;
    }
    const view = this.getView(maybeRoute as Route | 'Clicker' | 'Modal');
    // if we have a valid view for deep linking then navigate to route
    if (view) {
      const customDlFn = customDeepLinkCfg[view];

      let opts: any = undefined;
      if (typeof dlOpts === 'string') {
        try {
          opts = JSON.parse(dlOpts);
        } catch {
          /* fail silently */
        }
      } else if (typeof dlOpts === 'object') {
        opts = dlOpts;
      }

      // The custom function returns undefined if it handles the whole navigation within
      // Or returns the nav controller instance if it only handles the pre navigation setup like setting up some state prior to nav
      if (customDlFn) {
        const reroute = await customDlFn(this.app, opts);
        if (reroute) {
          this.goTo(reroute);
          return true;
        }
        return false;
      }

      this.goTo(view);
      return true;
    }
  };

  getDeeplinkOpts = (route: DeeplinkRoute) => {
    return deeplinkRoutes[route](this.app);
  };

  private getView = (
    route?: Route | 'Clicker' | 'Modal',
  ): Route | undefined => {
    if (Object.keys(this.app.views).includes(route || '')) {
      return route as Route;
    }
    return undefined;
  };
}

/**
 * DEFINE HERE CUSTOM SPECIFIC DEEPLINK CONFIG
 */
const deeplinkRoutes = {
  TradingTokenPage: (app: AppController) => {
    return {
      offchainTokenId: app.memes.currentMeme.token?.id,
    };
  },
  ProfilePage: (app: AppController) => {
    return {
      userId: app.profile.current?.id,
    };
  },
  TiktokMemeDetailsPage: (app: AppController) => {
    return {
      offchainTokenId: app.memes.currentMeme.token?.id,
    };
  },
};
type DeeplinkRoute = keyof typeof deeplinkRoutes;

/**
 * Handles coming into the app via a deeplink url; Make any preparations needed
 * and return route to navigate to or undefined if you are handling all navigation
 * within custom handler
 */
type CustomDeeplinkFn<T> = (
  app: AppController,
  opts?: T,
) => Promise<Optional<keyof typeof app.views>>;

const TradingTokenPage: CustomDeeplinkFn<
  ReturnType<typeof deeplinkRoutes.TradingTokenPage>
> = async (app, opts) => {
  app.ui.suspendInitialModals();
  if (opts?.offchainTokenId) {
    // If the user has tiktok enabled, then redirect to the tiktok feed instead
    if (isTiktokEnabled()) {
      await app.nav.goToTiktokReferredMeme(opts.offchainTokenId);
    } else {
      await app.nav.goToTradingToken(opts.offchainTokenId);
    }
  }
  app.ui.resumeInitialModals();
  return undefined;
};

const ProfilePage: CustomDeeplinkFn<
  ReturnType<typeof deeplinkRoutes.ProfilePage>
> = async (app, opts) => {
  if (opts?.userId) {
    await app.nav.goToProfile(opts.userId);
  }
  return undefined;
};

type DeeplinkHandlerFns = typeof TradingTokenPage | typeof ProfilePage;

const customDeepLinkCfg: Record<string, DeeplinkHandlerFns> = {
  TradingTokenPage,
  ProfilePage,
};
