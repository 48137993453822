// @todo: remove (POST SEASON 2 MIGRATION)
// import { ClickerPage } from './components/pages/ClickerPage/ClickerPage';
// import JoinTeam from './components/pages/TeamPages/JoinTeam';
// import TeamPage from './components/pages/TeamPages/TeamPage';
// import { Shop } from './components/pages/Shop/Shop';
// import { LeaguePage } from './components/pages/LeaguePage/LeaguePage';
// import { MinePage } from './components/pages/MinePage/MinePage';
// import { TradingCreateLinksPage } from './components/pages/TradingCreatePage/TradingCreateLinksPage';
// import { TradingEditLinksPage } from './components/pages/TradingCreatePage/TradingEditLinksPage';

import { ErrorBoundary } from 'react-error-boundary';
import { FriendsPage } from './components/pages/FriendsPage/FriendsPage';

import {
  AppEvents,
  app,
  useAppUpdates,
} from './data/Controllers/AppController';
import { ErrorInfo, useEffect } from 'react';
import { isDesktop, isMobileEmulatedByBrowser } from './data/device';
import { assets } from './assets/assets';
import { Toast } from './components/shared/Toast/Toast';

import { env, qpConfig } from './data/config';
import { LoadingScreen } from './components/shared/LoadingScreen/LoadingScreen';
import { BannedScreen } from './components/shared/LoadingScreen/BannedScreen';
import MaintenancePage from './components/pages/Maintenance/MaintenancePage';
import { AppErrorFallback } from './components/shared/AppErrorFallback/AppErrorFallback';
import { captureGenericError } from './data/sentry';
import { analytics } from '@play-co/gcinstant';
import { TutorialOverlay } from './components/Tutorial/TutorialOverlay';
import { Popup } from './components/modals/Popup';
import { Drawers } from './components/modals/Drawer';
import { ModalConfetti } from './components/shared/Confetti/ConfettiEffect';
import { Tooltip } from './components/modals/Tooltip';

import { EarnPage } from './components/pages/EarnPage/EarnPage';
import { TradingPage } from './components/pages/TradingPage/TradingPage';
import { TradingTokenPage } from './components/pages/TradingTokenPage/TradingTokenPage';
import { TradingCreatePage } from './components/pages/TradingCreatePage/TradingCreatePage';
import { Slideshow } from './components/Tutorial/Slideshows/Slideshow';
import { Spinner } from './components/shared/Spinner/Spinner';
import { ProfilePage } from './components/pages/ProfilePage/ProfilePage';
import { TiktokPage } from './components/pages/TiktokPage/TiktokPage';
import { TiktokSearchPage } from './components/pages/TiktokSearchPage/TiktokSearchPage';
import { TiktokMemeDetailsPage } from './components/pages/TiktokMemeDetailsPage/TiktokMemeDetailsPage';
import { TiktokFooter } from './components/pages/TiktokPage/TiktokFooter/TiktokFooter';
import { LoadingPage } from './components/pages/LoadPage';
import { TonTest } from './components/TonTest';
import { TonController } from './data/TonProvider/TonController';

// todo: commenting this out since we dont knwo what these are for, and are causing errors with slider dragging
// todo: remove if firther testing in real device is successful

// window.addEventListener('touchmove', (e) => e.preventDefault(), {
//   passive: true,
// });

// document.documentElement.addEventListener('touchmove', () => true, {
//   passive: false,
// });

// ---------------------------------------------------------------

// Note:
// change this value whenever we want to enable or disable maintainance page
// whitelisted users will still be able to close it, while normal users will be blocked by it
const showMaintenancePageOnStart = false;

function onReactRenderError(error: Error, info: ErrorInfo) {
  analytics.pushError('ReactRenderError', error, {
    componentStack: info?.componentStack,
    digest: info?.digest,
  });
  captureGenericError('ReactRenderError', error, {
    extra: {
      componentStack: info?.componentStack,
      digest: info?.digest,
    },
  });
}

function Application() {
  useAppUpdates({
    id: 'App',
    listener: app.attachEventListener(AppEvents.onAppReady),
  });

  useEffect(() => {
    // open maintancePage if necessary (defined at the top of this file)
    if (showMaintenancePageOnStart) {
      app.views.Maintenance.show();
    }

    // ----------------------------------------------------------
    // prevent text-selection/magnifier-glass when double tapping across the whole app

    function createDoubleTapPreventer(timeout_ms: number) {
      let dblTapTimer: any; //  = undefined;
      let dblTapPressed = false;

      return function (e: TouchEvent) {
        clearTimeout(dblTapTimer);
        if (dblTapPressed) {
          e.preventDefault();
          dblTapPressed = false;
        } else {
          dblTapPressed = true;
          dblTapTimer = setTimeout(() => {
            dblTapPressed = false;
          }, timeout_ms);
        }
      };
    }

    document.body.addEventListener(
      'touchstart',
      createDoubleTapPreventer(500),
      { passive: false },
    );
    // ----------------------------------------------------------
  }, []);

  useEffect(() => {
    // preload earn page content
    if (app.replicant) {
      app.views.EarnPage.fetch();
    }
  }, [app.replicant]);

  // Desktop will just display a telegram qr code
  if ((isDesktop() || isMobileEmulatedByBrowser()) && !qpConfig.skipQR) {
    // todo(Cai): how can we get the current environment? local / dev / staging / prod
    console.warn('>>> environment:', env);

    return (
      <div className="disable-dbl-tap-zoom">
        <div className="app-container">
          <div className="qr-code">
            <img src={assets.qrcode[env]} />
            <div className="label">Scan QR code with your phone camera</div>
          </div>
        </div>
      </div>
    );
  }

  const getContent = () => {
    if (!app.isReady) {
      return null;
    }

    if (app.isUserBanned) {
      return <BannedScreen />;
    }

    if (showMaintenancePageOnStart) {
      return <MaintenancePage />;
    }

    if (TonController.TestUIEnabled) {
      return <TonTest />;
    }

    return (
      <>
        <TutorialOverlay />
        <Popup />
        <Drawers />
        <ModalConfetti />
        <Tooltip />

        {/* Pages */}
        <LoadingPage />
        {/* @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* <ClickerPage /> */}
        {/* <JoinTeam /> */}
        {/* <TeamPage /> */}
        <FriendsPage />
        {/* @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* <Shop /> */}
        <Toast />
        <EarnPage />
        {/* @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* <LeaguePage /> */}
        {/* <MinePage /> */}
        <TradingPage />
        <TradingTokenPage />
        <TradingCreatePage />
        {/* @todo: remove (POST SEASON 2 MIGRATION) */}
        {/* <TradingCreateLinksPage />
        <TradingEditLinksPage /> */}

        {/* tiktok related pages and components */}
        <ProfilePage />
        <TiktokPage />
        <TiktokSearchPage />
        <TiktokMemeDetailsPage />
        <TiktokFooter />

        {/* slidehows */}
        <Slideshow />

        {/* full screen spinner */}
        <Spinner />
      </>
    );
  };

  // Mobile will play the app
  return (
    <div className="disable-dbl-tap-zoom">
      <div className="app-container">
        <ErrorBoundary
          FallbackComponent={AppErrorFallback}
          onError={onReactRenderError}
        >
          {/* present loading screen while app is still not ready */}
          <LoadingScreen visible={!app.isReady} />

          {/* display game content once app is ready */}
          {getContent()}
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Application;
