import './DrawerFtueShare.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { displayPointAmount } from '../../../replicant/utils/numbers';
import {
  getBuyTxEstimate,
  getFtueShareGateReward,
  TradingToken,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';

import { motion } from 'motion/react';
import { t } from 'i18next';
import { SEC_IN_MS } from '../../../replicant/utils/time';
import { memeGiftRuleset } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { HP } from '../../../replicant/lib/HighPrecision';

// ===================================================================================
// #region FTUE Share

export const DrawerFtueShare = () => {
  const { t } = useTranslation();

  // get initial random token asynchronously
  const [token, setToken] = useState<TradingToken>();
  const [tokenId, setTokenId] = useState<string>('');
  useEffect(() => {
    if (!token) {
      app.memes.getFtueShareInitialMeme().then(async (tokenId) => {
        if (!tokenId) {
          return;
        }

        setTokenId(tokenId);

        const token = await app.memes.getToken(tokenId, 'forceFetch');

        if (token) {
          setToken(token);
        }
      });
    }
  }, [token]);

  // should we display progress bars or final screen?
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      app.track('FTUEprofileAnalysisLoad');
    } else {
      app.track('FTUEprofileAnalysisComplete');
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingScreen onComplete={() => setIsLoading(false)} />;
  } else {
    if (!token) {
      return <TokenNofFound tokenId={tokenId} />;
    }
    return <SharingScreen token={token} />;
  }
};

// ===================================================================================
// #region Error

const TokenNofFound = ({ tokenId }: { tokenId: string }) => {
  return (
    <div className="drawer-ftue-share">
      <div className="drawer-ftue-share-error-container">
        <div className="drawer-ftue-share-error-title">{`FTUE SHARE GATE`}</div>
        <div className="drawer-ftue-share-error-title">{`Could not retrieve meme:`}</div>
        <div className="drawer-ftue-share-error-list">
          <div className="drawer-ftue-share-error-message">• {tokenId}</div>
        </div>
        <div
          className="btn btn-normal drawer-ftue-share-error-button"
          onClick={app.ui.drawer.close}
        >
          {`Close`}
        </div>
      </div>
    </div>
  );
};

// ===================================================================================
// #region Loading

const LoadingScreen = ({ onComplete }: { onComplete: () => void }) => {
  const duration = 0.7;
  // const delay = 3;

  // const m = 0.35;

  const [isComplete, setIsComplete] = useState(false);

  return (
    <div className="drawer-ftue-share">
      <motion.div
        className="drawer-ftue-share-loading-container"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{
          opacity: isComplete ? 0 : 1,
          transform: isComplete ? 'translateY(-40px)' : 'translateY(0px)',
        }}
        transition={{ duration: 0.5, delay: 0.25 }}

        // onTransitionEnd={(hello) => {S
        //   console.warn('>>> onTransitionEnd', hello, isComplete);
        //   if (isComplete) onComplete();
        // }}
      >
        {/* <div className="drawer-ftue-share-loading-container"> */}
        <div className="drawer-ftue-share-loading">
          <div className="drawer-ftue-share-checking">{t('ftue_checking')}</div>
          <div className="drawer-ftue-share-loading-bars">
            <ProgressBar
              caption={t('ftue_loading_bar1')}
              color={'yellow'}
              duration={duration}
              delay={0.5}
            />
            <ProgressBar
              caption={t('ftue_loading_bar2')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 1 - 0.2}
            />
            <ProgressBar
              caption={t('ftue_loading_bar3')}
              color={'yellow'}
              duration={duration}
              delay={0.5 + duration * 2 - 0.4}
            />
            <ProgressBar
              caption={t('ftue_loading_bar4')}
              color={'yellow'}
              duration={duration * 2.5}
              delay={0.5 + duration * 2.5 - 0.4}
              onComplete={() => {
                setTimeout(() => {
                  setIsComplete(true);
                  setTimeout(() => onComplete(), 350);
                }, 350);
              }}
            />
          </div>
        </div>
        {/* </div> */}
      </motion.div>
    </div>
  );
};

// ===================================================================================
// #region Sharing

const SharingScreen = ({ token }: { token: TradingToken }) => {
  const instantReward = getBuyTxEstimate({
    currentSupply: HP(token.supply),
    currencyAmount: HP(getFtueShareGateReward(app.state)),
  });

  const instantRewardTxt = displayPointAmount(
    instantReward.toNumber(),
    4,
    true,
    true,
  );

  const supply = HP(token?.supply ?? 0);
  const giftCoinEquivalent = memeGiftRuleset.giftReward;
  const giftPoints = getBuyTxEstimate({
    currentSupply: supply,
    currencyAmount: HP(giftCoinEquivalent),
  });

  const onTapShareButton = async () => {
    await app.memes.sendGift(token, 'ftue_share_gate');

    app.invoke.grantFtueShareReward({ memeId: token.id });

    app.nav.goToTiktokFeed(token.id);

    setTimeout(() => {
      app.ui.drawer.close();
    }, 5 * SEC_IN_MS);
  };

  useEffect(() => {
    if (token) {
      app.track('FTUEmemeGiftPageLoad', {
        MemeId: token.id,
        MemeTicker: token.ticker,
        pointAmount: instantReward.toString(),
        giveShareAmount: giftPoints.toString(),
      });
    }
  }, [token]);

  if (!token) {
    return;
  }

  return (
    <div className="drawer-ftue-share">
      {/* top header - empty */}
      <motion.div
        className="drawer-ftue-share-header"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.75, delay: 0 }}
      >
        <div className="drawer-ftue-share-header-title">
          {t('share_meme_gift_title')}
        </div>
      </motion.div>

      <motion.div
        className="drawer-ftue-share-header"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.65, delay: 0.1 }}
      >
        <div className="drawer-ftue-share-header-subtitle">
          {t('share_meme_gift_subtitle', { memeName: token?.name })}
        </div>
      </motion.div>

      {/* centered content */}
      <motion.div
        className="drawer-ftue-share-content"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.55, delay: 0.2 }}
      >
        <div className="drawer-ftue-header-icon">
          <div className="img-header">
            <div className="img-container">
              <img src={token.image} />
            </div>
            <div className="overlay-img-header">
              <div className="overlay-img-container">
                <img src={assets.gift_icon} />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="drawer-ftue-share-content"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.45, delay: 0.3 }}
      >
        <div
          className="drawer-ftue-share-token-value-text"
          dangerouslySetInnerHTML={{
            __html: instantRewardTxt,
          }}
        ></div>
      </motion.div>

      {/* bottom buttons  */}

      <motion.div
        className="drawer-ftue-share-buttons"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.35, delay: 0.4 }}
      >
        {/* give and get */}
        <div className="drawer-ftue-share-giveAndGet">
          <div className="drawer-ftue-share-giveAndGet-text">
            {t('user_meme_gift_give_get', {
              amount: displayPointAmount(
                giftPoints.toNumber(),
                3,
                false,
                false,
              ),
            })}
          </div>
          {/* <div className="drawer-ftue-share-giveAndGet-text separator">{`/`}</div>
          <div className="drawer-ftue-share-giveAndGet-text">{`Get ${getValue}`}</div> */}
        </div>
      </motion.div>

      <motion.div
        className="drawer-ftue-share-buttons"
        initial={{ opacity: 0, transform: 'translateY(40px)' }}
        animate={{ opacity: 1, transform: 'translateY(0px)' }}
        transition={{ duration: 0.25, delay: 0.5 }}
      >
        <div
          className="btn btn-normal drawer-ftue-share-button"
          onClick={onTapShareButton}
        >
          {`Share`}
        </div>
      </motion.div>
    </div>
  );
};

// ===================================================================================
// #region Progress Bar

interface ProgresBarProps {
  caption: string;
  color: string;
  duration: number;
  delay: number;
  onComplete?: () => void;
}

const ProgressBar = ({
  caption,
  color,
  duration,
  delay,
  onComplete,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  // start

  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (!hasStarted) {
      setTimeout(() => setHasStarted(true), delay * 1000);
    }
  }, []);

  // progress

  const [currentFrame, setCurrentFrame] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);

  useEffect(() => {
    if (!hasStarted) return;

    // progress interval
    const fps = 30;
    const maxFrames = duration * fps;
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => prevFrame + 1);
      setPercentageComplete(Math.floor((currentFrame / maxFrames) * 100));
    }, fps);

    // progress end
    if (currentFrame > maxFrames) {
      setPercentageComplete(100);
      clearInterval(interval);
      onComplete && onComplete();
    }

    return () => clearInterval(interval);
  }, [currentFrame, hasStarted]);

  // console.warn('>>>', percentageComplete);

  if (!hasStarted) {
    return null;
  }

  // render

  return (
    <div className="progress-bar-container-v">
      {/* message */}
      <div className="progress-bar-text">{caption}</div>
      {/* progress bar horizontal container (bar + icon) */}
      <div className="progress-bar-container-h">
        {/* progress bar */}
        <div className="progress-bar">
          <div className="progress-bar-bg" />
          <div
            className={`progress-bar-value ${color}`}
            style={{ width: `${percentageComplete}%` }}
          ></div>
        </div>
        {/* progress bar icon*/}
        {percentageComplete >= 100 && (
          <motion.div
            className={`progress-bar-icon`}
            initial={{ opacity: 0, transform: 'translateX(30px)' }}
            animate={{ opacity: 1, transform: 'translateX(0px)' }}
            transition={{ duration: 0.25, delay: 0 }}
          >
            <img src={assets.ftue_icon_check} />
          </motion.div>
        )}
      </div>
    </div>
  );
};

// ===================================================================================
