import { IAPConfig } from '../../../replicant/features/tradingMeme/types';
import { app, AppController, isLocal } from '../AppController';
import { newTokenFormConfig, updateTokenFormConfig } from './types';
import { Form } from '../../Form';
import { Tutorials } from '../../tutorial/types';
import { v4 as uuid } from 'uuid';
import {
  IAP_TG_STARS_TIMEOUT_MS,
  tmgRuleset,
} from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { ErrorCode } from '../../../replicant/response';
import { waitFor } from '../../utils';
import { MIN_IN_MS } from '../../../replicant/utils/time';
import { hasReachedMemeCreationLimit } from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import { assets } from '../../../assets/assets';
import { t } from 'i18next';

// @warning-start: do not change the prefix or it can break moderation
const OFFCHAIN_TRADING_PREFIX = 'offchainTrading';
const S3_PATH_PREFIX = 'trading';
// @warning-end
const TG_START_TX_SYNC_POLL_TIME = 1 * MIN_IN_MS;
const DEFAULT_TOKEN_IMAGE = 'https://notgemz.cms.gemz.fun/media/default.png';

/**
 * This class does not need to be business or pure - it only provides an API
 * There's no events
 */
export class TokenFactory {
  public newTokenForm = new Form(newTokenFormConfig);

  public updateLinksForm = new Form(updateTokenFormConfig);

  private createTokenFormData?: typeof this.newTokenForm.data;

  public get starsPrice() {
    return this.app.session.getIAPConfig()?.priceInStars.toString() ?? '0';
  }

  public get createNewTokenFormData() {
    return this.createTokenFormData;
  }

  private tgStarsTxSyncPool?: NodeJS.Timeout;

  constructor(private app: AppController) {}

  public createNewToken = async () => {
    if (hasReachedMemeCreationLimit(this.app.state)) {
      this.app.ui.drawer.show({
        id: 'drawerTradingWarning',
        props: {
          tradingWarning: {
            warningTitle: t('meme_limit_reached_creation_title'),
            warningMessage: t('meme_limit_reached_creation_message', {
              limit: tmgRuleset.creationLimit,
            }),
            warningCta: t('meme_limit_reached_creation_cta'),
            icon: assets.trading_transaction_success,
          },
        },
      });

      return;
    }

    this.app.track('memeoffchainToken_create_start');
    this.newTokenForm.clearForm();
    // start trading tutorial slideshow
    await this.app.tutorial.startTutorial(
      Tutorials.SlideshowTradingCreateToken,
      {
        substitutes: { starAmount: this.starsPrice },
      },
    );

    // open TradingCreatePage
    this.app.tutorial.step?.onAction &&
      this.app.tutorial.step?.onAction('trading-create');
    this.app.nav.goTo('TradingCreatePage');
  };

  public submitFormCreateToken = async (retries = 0): Promise<void> => {
    if (!this.newTokenForm.isValid) {
      console.log('Form is not valid');
      return;
    }

    this.createTokenFormData = { ...this.newTokenForm.data };

    //@CAI: We we creating token at this point, maybe it's a better place to create the draft?

    this.app.memes.trading.onTokenCreated();
    this.app.track('memeoffchainToken_form_submit', this.createTokenFormData);
    this.app.ui.drawer.show(
      {
        id: 'drawerTradingCreateConfirm',
        hideClose: true,
      },
      true,
    );
  };

  private get targetToken() {
    return this.app.memes.currentMeme.token;
  }

  public submitFormUpdateLinks = async (): Promise<void> => {
    if (!this.targetToken) {
      return;
    }

    await this.app.invoke.asyncEditOffchainToken({
      tokenId: this.targetToken.id,
      ...this.updateLinksForm.data,
    });

    // Update the current token
    this.app.memes.getToken(this.targetToken.id, 'fetchAndUpdate');

    // track update links analytics
    this.app.track('memecard_edit', {
      cardId: this.targetToken.id || '',
      cardName: this.targetToken.name || '',
    });

    // go back to original token page
    this.app.nav.back();
  };

  // private createOffchainTokenLocally = async () => {
  //   const { state } = this.app.memes.trading;

  //   if (!state.tx || !this.createTokenFormData) {
  //     return;
  //   }

  //   try {
  //     const createOffchainTokenResponse =
  //       await this.app.invoke.asyncCreateOffchainToken({
  //         offchainToken: this.createTokenFormData,
  //         currencyAmount: state.tx.send.toString(),
  //         productId: `foo`,
  //         isLocal: true,
  //         useCredit: false,
  //       });

  //     if (createOffchainTokenResponse.error) {
  //       return this.app.ui.showError({
  //         message: createOffchainTokenResponse.error,
  //       });
  //     }

  //     if (createOffchainTokenResponse.data) {
  //       const offchainToken = await this.app.memes.getToken(
  //         createOffchainTokenResponse.data.offchainTokenId,
  //         'forceFetch',
  //       );
  //       if (offchainToken) {
  //         this.app.invoke.asyncTakePortfolioSnapshots();

  //         // navigate back to pump page and show success drawer
  //         this.app.ui.onCreateOffchainTokenSuccess(offchainToken);
  //       }
  //     }
  //   } catch (e: any) {
  //     this.app.ui.showError({
  //       message: e.message,
  //     });
  //   } finally {
  //     this.createTokenFormData = undefined;
  //   }
  // };

  createOffchainToken = async () => {
    const { state } = this.app.memes.trading;

    if (!state.tx || !this.createTokenFormData) {
      return;
    }

    if (!this.app.ton.walletAddress) {
      await this.app.ton.connect();
      try {
        await this.app.ton.waitForWalletAddress();
      } catch {
        return this.app.ui.showError({
          message: `You must connect you wallet to create a meme`,
        });
      }
    }

    try {
      if (!this.app.ton.walletAddress) {
        throw new Error(
          `How did this happen? Cannot create meme without wallet address`,
        );
      }
      const memeIdResponse = await this.app.invoke.asyncGetNewMemeId();

      if (memeIdResponse.error) {
        return this.app.ui.showError({
          message: memeIdResponse.error,
        });
      }

      const { memeId } = memeIdResponse.data;

      this.app.ui.showSpinner();

      // @CAI: re-enable this
      // if (isLocal) {
      //   return this.createOffchainTokenLocally();
      // }

      this.app.track('memeoffchainToken_init_creation_purchase', {
        memeoffchainToken_name: this.createTokenFormData.name,
        creator_invested_amount: state.tx.send.toString(),
      });

      // get all properties except image
      const { image: _, ...rest } = this.createTokenFormData;

      const props = {
        memeId,
        memeInput: {
          creatorWalletAddress: this.app.ton.walletAddress,
          image: DEFAULT_TOKEN_IMAGE,
          ...rest,
        },
        isLocal: false,
      };

      console.log('createOffchainToken', { props, memeId });

      // @TODO: We need to save the wallet address here
      const createOffchainTokenResponse = await this.app.invoke.asyncCreateMeme(
        props,
      );

      if (createOffchainTokenResponse.error) {
        return this.app.ui.showError({
          message: createOffchainTokenResponse.error,
        });
      }

      await this.app.invoke.flushMessages();

      const imageKey = `${OFFCHAIN_TRADING_PREFIX}-${memeId}`;

      console.log({ imageKey });

      await this.app.replicant.uploadUserAsset(
        this.createTokenFormData.image,
        `${S3_PATH_PREFIX}/${imageKey}/`,
      );

      this.app.track('memecard_created', {
        memecard_name: this.createTokenFormData.name,
        cardID: memeId,
        owner_amount_invested: state.tx.send.toString(),
      });

      const offchainToken = await this.app.memes.getToken(memeId, 'forceFetch');

      const jettonContractAddress = await this.app.ton.createContract({
        memeId,
        name: props.memeInput.name,
        description: props.memeInput.description,
        symbol: props.memeInput.ticker,
        image: props.memeInput.image,
      });

      console.log('createOffchainToken', { jettonContractAddress });

      if (jettonContractAddress) {
        this.app.invoke.onJettonContractMinted({
          memeId,
          jettonContractAddress,
        });
      }

      // @TODO: send event instead of this
      this.app.memes.onTokenCreated(memeId);
      this.app.ui.onCreateOffchainTokenSuccess(offchainToken);
      console.log('createOffchainToken', '???');

      this.createTokenFormData = undefined;
      this.newTokenForm.clearForm();
    } catch (e: any) {
      const isTimeout = e.message === ErrorCode.IAP_TG_STARS_TIMEOUT;

      const showErrorProps: Parameters<typeof this.app.ui.showError>[0] = {
        message: e.message,
      };

      if (isTimeout) {
        this.app.track('Stars_dialog_fail', {
          memecard_name: this.createTokenFormData?.name ?? 'unknown',
          owner_amount_invested: state.tx.send.toString(),
        });

        showErrorProps.title = 'Telegram Timeout';
        showErrorProps.message = `Telegram is taking too long to respond.\nPlease try again later.`;
        showErrorProps.cta = 'Ok';
        showErrorProps.onClick = () => this.app.nav.goToTiktokFeed();
      }

      this.app.ui.showError(showErrorProps);
    } finally {
      this.app.ui.hideSpinner();
    }
  };
}
