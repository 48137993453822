import Big from 'big.js';

// @notes:
// - this is a wrapper around Big numbers that also accepts undefined values
// - it will also later be used to make the interface between offchain and onchain Big types

// type HighPrecisionSerialized = { value: string };

export type HighPrecisionInput =
  | string
  | number
  | Big
  | undefined
  // | HighPrecisionSerialized
  | HighPrecision;

interface HighPrecisionOpts {
  id?: string;
  strict?: boolean;
  fixed?: number;
}

export class HighPrecision {
  private value!: Big;

  // @TODO: We can potentially make it "more strict" by validating string for NumericString first
  constructor(value: HighPrecisionInput, private opts?: HighPrecisionOpts) {
    this.set(value);
  }

  private getBigSource = (val: HighPrecisionInput) => {
    if (val === undefined) {
      if (this.opts?.strict) {
        throw new Error(
          `Cannot accept 'undefined' as value for strict high precision '${
            this.opts?.id ?? ''
          }'.`,
        );
      }

      return 0;
    }

    // console.error('2. val instanceof HighPrecision', val instanceof HighPrecision);
    if (val instanceof HighPrecision) {
      return val.value;
    }

    if (val instanceof Big) {
      return val;
    }

    // if (typeof val === 'object') {
    //   if (val.value === undefined) {
    //     throw new Error(
    //       `Cannot extract source of high precision number ${JSON.stringify(val)} '${
    //         this.opts?.id ?? ''
    //       }'.`
    //     );
    //   }

    //   return val.value;
    // }

    return val;
  };

  public set = (value: HighPrecisionInput) => {
    if (value instanceof Big) {
      this.value = value;
    } else {
      const bigSource = this.getBigSource(value);
      // console.error('3. bigSource', bigSource, value);
      try {
        this.value = Big(bigSource);
      } catch (error) {
        console.error('WTF??', value);
      }
    }
    return this;
  };

  public toString() {
    return this.value.toString();
  }

  public toFixed(num: number) {
    return this.value.toFixed(num);
  }

  public toNumber() {
    return this.value.toNumber();
  }

  private getHighPrecisionNumber = (val: HighPrecisionInput): number => {
    if (val instanceof HighPrecision) {
      return val.toNumber();
    }

    if (val instanceof Big) {
      return val.toNumber();
    }

    if (typeof val === 'number') {
      return val;
    }

    if (typeof val === 'string') {
      return Big(val).toNumber();
    }

    // if (typeof val === 'object') {
    //   if (val.value === undefined) {
    //     throw new Error(
    //       `Cannot extract source of high precision number ${JSON.stringify(val)} '${
    //         this.opts?.id ?? ''
    //       }'.`
    //     );
    //   }

    //   return Big(val.value).toNumber();
    // }

    if (this.opts?.strict) {
      throw new Error(
        `Cannot accept 'undefined' as value for strict currency '${
          this.opts?.id ?? ''
        }'.`,
      );
    }

    return 0;
  };

  public eq = (value: HighPrecisionInput) =>
    this.value.eq(this.getBigSource(value));
  public gt = (value: HighPrecisionInput) =>
    this.value.gt(this.getBigSource(value));
  public gte = (value: HighPrecisionInput) =>
    this.value.gte(this.getBigSource(value));
  public lt = (value: HighPrecisionInput) =>
    this.value.lt(this.getBigSource(value));
  public lte = (value: HighPrecisionInput) =>
    this.value.lte(this.getBigSource(value));

  public add = (value: HighPrecisionInput) => {
    return new HighPrecision(this.value.add(this.getBigSource(value)));
  };

  public plus = (value: HighPrecisionInput) => {
    return new HighPrecision(this.value.plus(this.getBigSource(value)));
  };

  public minus = (value: HighPrecisionInput) => {
    return new HighPrecision(this.value.minus(this.getBigSource(value)));
  };

  public mul = (value: HighPrecisionInput) => {
    return new HighPrecision(this.value.mul(this.getBigSource(value)));
  };

  public div = (value: HighPrecisionInput) => {
    return new HighPrecision(this.value.div(this.getBigSource(value)));
  };

  public sqrt = () => {
    return new HighPrecision(this.value.sqrt());
  };

  public abs = () => {
    return new HighPrecision(this.value.abs());
  };

  public round = () => {
    return new HighPrecision(this.value.round());
  };

  public pow = (val: HighPrecisionInput) => {
    const hpNum = this.getHighPrecisionNumber(val);
    return new HighPrecision(this.value.pow(hpNum));
  };

  public exp = (val: HighPrecisionInput) => {
    const hpNum = this.getHighPrecisionNumber(val);
    return new HighPrecision(Math.exp(hpNum));
  };
}

export function HP(value: HighPrecisionInput, opts?: HighPrecisionOpts) {
  return new HighPrecision(value, opts);
}
