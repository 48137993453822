import { ErrorCode, isHandledError } from '../../replicant/response';
import { TonConnectError } from '@tonconnect/sdk';
import { TonProvider } from './TonProvider';
import { ONE_DAY_MS } from '../../replicant/features/game/ruleset/contract';
import { BusinessController } from '../Controllers/BusinessController';
import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import gameConfig from '../../replicant/features/game/game.config';
import { analytics } from '@play-co/gcinstant';
import { CreateContractInput } from './types';
import { waitFor } from '../utils';
import { Optional } from '../types';
import { qpConfig } from '../config';

const POOL_INTERVAL = 500;
const TIMEOUT_TRIES = 15; // 7.5s
const WAIT_WALLET_ADDRESS_TIMEOUT = 4; //2s

export class TonController extends BusinessController<''> {
  public static TestUIEnabled = qpConfig.testTON;

  public tonConnectUI!: TonConnectUI;

  private provider = new TonProvider();

  public get walletAddress() {
    return this.provider.walletAddress;
  }

  init = async () => {
    const stage = process.env.REACT_APP_STAGE || 'dev';
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${stage}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.app.replicantClientPromise;

        if (status) {
          this.app.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          analytics.setUserProperties({
            walletAddress: status.account.address,
          });

          this.provider.setWalletAddress(status.account.address);
        } else {
          this.provider.setWalletAddress(undefined);
        }
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.app.maybeFixBrokenWalletConnectQuest();
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  public waitForWalletAddress = async (retries = 0): Promise<void> => {
    if (retries > WAIT_WALLET_ADDRESS_TIMEOUT) {
      return Promise.reject();
    }
    await waitFor(POOL_INTERVAL);
    if (this.walletAddress) {
      return Promise.resolve();
    }
    return this.waitForWalletAddress(++retries);
  };

  connect = () => {
    if (!this.tonConnectUI.connected) {
      return this.tonConnectUI.openModal();
    }
    return Promise.resolve();
  };

  disconnect = async () => {
    await this.tonConnectUI.disconnect();
  };

  private waitForJettonAddress = async (
    offchainId: string,
    retry = 0,
  ): Promise<Optional<string>> => {
    console.log('waitForJettonAddress', { offchainId, retry });
    if (retry > TIMEOUT_TRIES) {
      throw new Error('TIMEOUT');
    }
    await waitFor(POOL_INTERVAL);

    try {
      const response = await this.provider.getJettonContractAddress(offchainId);
      console.log('waitForJettonAddress', { offchainId, retry, response });
      if (response) {
        return response;
      }

      return this.waitForJettonAddress(offchainId, ++retry);
    } catch (e) {
      throw e;
    }
  };

  createContract = async (input: CreateContractInput) => {
    await this.connect();

    try {
      if (!this.tonConnectUI.connected) {
        throw this.onError(
          ErrorCode.OC_TON_NOT_READY,
          ErrorCode.OC_TON_NOT_READY,
        );
      }

      const createJettonContractTx =
        await this.provider.getCreateJettonContractTx(input, this.app.now());

      const response = await this.tonConnectUI.sendTransaction(
        createJettonContractTx,
      );

      const jettonContractAddress = await this.waitForJettonAddress(
        input.memeId,
      );
      console.log('createContract', { jettonContractAddress });

      return jettonContractAddress;
    } catch (e: any) {
      if (!isHandledError(e)) {
        console.error(e);
      }

      throw e;
    }
  };

  checkIn = async () => {
    if (this.app.now() - this.app.state?.dailyContractCheckin < ONE_DAY_MS) {
      console.log('Already checked in today');
      return;
    }

    await this.connect();

    const checkInTx = this.provider.getCheckInTx(this.app.now());

    return this.tonConnectUI.sendTransaction(checkInTx);
  };

  buyToken = async (creatorAddress: string, tokenId: string, amount: Big) => {
    await this.connect();

    // @TODO: Review this (same issue as TonProvider.getJettonContractAddress)
    const offchainId = BigInt(tokenId);

    const buyTokenTx = await this.provider.getBuyTokenTx(
      {
        ownerAddress: creatorAddress,
        amount: BigInt(amount.toString()),
        memeId: offchainId,
      },
      this.app.now(),
    );

    return this.tonConnectUI.sendTransaction(buyTokenTx);
  };

  sellToken = async (tokenId: string, amount: Big) => {
    await this.connect();

    // @TODO: Review this (same issue as TonProvider.getJettonContractAddress)
    const offchainId = BigInt(tokenId);

    const sellTokenTx = await this.provider.getSellTokenTx(
      {
        amount: BigInt(amount.toString()),
        memeId: offchainId,
      },
      this.app.now(),
    );

    return this.tonConnectUI.sendTransaction(sellTokenTx);
  };

  ping = async (ping: string) => {
    await this.connect();

    const tx = this.provider.getPingTx(ping, this.app.now());
    try {
      await this.tonConnectUI.sendTransaction(tx);
      const waitForPong = async (retry = 0): Promise<Optional<string>> => {
        console.log('waitForJettonAddress', { retry });
        if (retry > TIMEOUT_TRIES) {
          throw new Error('TIMEOUT');
        }
        await waitFor(POOL_INTERVAL);

        try {
          const response = await this.provider.getPong();
          console.log('waitForJettonAddress', { retry, response });
          if (response) {
            return response;
          }

          return waitForPong(++retry);
        } catch (e) {
          throw e;
        }
      };
      console.log(await waitForPong());
    } catch {
      console.log('Failed to ping/pong');
    }
  };

  // This is used to render the test page
  public ui = {
    connect: this.connect,
    disconnect: this.disconnect,
    createContract: this.createContract,
    checkIn: this.checkIn,
    buyToken: this.buyToken,
    sellToken: this.sellToken,
    getContactAddressList: this.provider.getContactAddressList,
    getJettonContractAddress: this.provider.getJettonContractAddress,
    getJettonTokenSupply: this.provider.getJettonTokenSupply,
    ping: this.ping,
  };
}
