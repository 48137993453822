import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const chainSchema = SB.tuple(['dex', 'jetton']);

export type ChainName = SB.ExtractType<typeof chainSchema>;

export const onchainTxsSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      memeId: SB.string(),
      chain: chainSchema,
      page: SB.number(),
      txHashes: SB.array(SB.string()),
    }),
  },
});

export type OnchainTxsState = SB.ExtractType<
  typeof onchainTxsSharedStateSchema.global.schema
>;

export type MutableOnchainTxsState = WithMeta<OnchainTxsState, {}>;
