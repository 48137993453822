import { Address, beginCell, Cell, SenderArguments, toNano } from '@ton/core';
import { buildOnchainMetadata, stringToBigInt, storeBuilders } from './utils';
import { CHAIN } from '@tonconnect/sdk';
import {
  Base64,
  CreateContractInput,
  GetContractAddressByOffchainIdResponse,
  Getters,
  Signature,
  StackTuple,
  BuyTokenInput,
  SellTokenInput,
} from './types';
// @TODO: Ideally remove these dependencies to make the Provider "pure"
import { MIN_IN_MS } from '../../replicant/utils/time';
import { apiRequest } from '../api';
import { ErrorCode, isHandledError } from '../../replicant/response';
import {
  DAILY_CHECKIN_CONTRACT_ADDRESS,
  DAILY_CHECKIN_CONTRACT_LABEL,
  DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE,
} from '../../replicant/features/game/ruleset/contract';
import TonWeb from 'tonweb';
import { Optional } from '../types';

// Put them in order they were create and revert to show them latest to oldest
export const adminContractAddresses = [
  'EQD78QRViePVIBhd3Ma2HkfmfyzhJ57y1SbtO_tb_Lrw9p87',
  'EQD_GoAgmR_ZALZ30JXGM_jNiEAW7uCCnO-pynGgKuyYRXv7',
  'EQDZ6ABNEUVT9rtHn-hvHeW-lkyz50lR8GOHDAeLydNV_ZqV',
  'EQCK_yeGF7sG_gBHnW6Ja-HjZRoLc0tNo4_KJx6UWGR6LSWi',
  'EQDwVTiBsO4JVCxH5_6xoRReAZG0dSm_kdCS9zyULJPUmzQM',
  'EQAFMw6KPs0pBMRb-Ic7xld2qFxOIBVxy8NNNmaYij3WK7dW',
  'EQArcoIqXsntua5c0ZUyTzQP-jasXv7klYNUYvmPWcPsXNc1',
  'EQCm_9kyAJDdo4cEgGreg0vmOylcIB_W0LdLvAhSmHyMuLTe',
].reverse();

const HTTP_RPC_URL = 'https://toncenter.com/api/v2/jsonRPC';

export class TonProvider {
  public static config = {
    jetton: {
      deployContractFee: 0.01,
    },
  };

  public static adminContractAddress = adminContractAddresses[0];

  private _walletAddress?: string;

  public get walletAddress() {
    return this._walletAddress;
  }

  /**
   * @lazy use pubClient instead
   */
  private _pubClient?: TonWeb;
  // Lazy instantiate the first time we use the pubClient
  private get pubClient(): TonWeb {
    if (!this._pubClient) {
      this._pubClient = new TonWeb(new TonWeb.HttpProvider(HTTP_RPC_URL));
    }
    return this._pubClient;
  }

  public setWalletAddress = (walletAddress?: string) => {
    this._walletAddress = walletAddress;
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  // 5min
  private getExpiry = (now: number, ttl = 5 * MIN_IN_MS) => {
    return now + ttl;
  };

  private sanitizeDataToSign = (
    data: Record<string, any>,
  ): Record<string, string> => {
    return Object.keys(data).reduce((res, cur) => {
      res[cur] = data[cur].toString();
      return res;
    }, {} as Record<string, string>);
  };

  private getSignature = async (
    method: 'deployJettonContract' | 'buyToken' | 'sellToken',
    data: Record<string, any>,
  ): Promise<Signature> => {
    try {
      const {
        result: { nonceB64, signatureB64 },
      } = await apiRequest<{
        result: {
          nonceB64: string;
          signatureB64: string;
          timestamp: string;
        };
      }>('https://oracle.pnk.one/sign').post({
        method,
        data: this.sanitizeDataToSign(data),
      });

      const bocBuffer = Buffer.from(nonceB64, 'base64');
      // Deserialize the Buffer to a Cell
      const nonce = Cell.fromBoc(bocBuffer)[0];

      const sigBuffer = Buffer.from(signatureB64, 'base64');
      const signature = beginCell().storeBuffer(sigBuffer).asSlice();

      return {
        nonce,
        signature,
      };
    } catch (err) {
      throw this.onError(err, ErrorCode.OC_TON_SIGNATURE_FAILED);
    }
  };

  private getTx = ({ to, value, body }: SenderArguments, now: number) => {
    const address = to.toString();
    const amount = value.toString();
    const payload = body?.toBoc().toString('base64');
    return {
      validUntil: this.getExpiry(now),
      messages: [{ address, amount, payload }],
      network: CHAIN.MAINNET,
    };
  };

  private getAdminSenderArgs = (
    body: Cell,
    value: number | string | bigint = 0.5,
  ): SenderArguments => {
    return {
      to: Address.parse(TonProvider.adminContractAddress),
      value: toNano(value),
      body,
    };
  };

  public getCheckInTx = (now: number) => {
    const args: SenderArguments = {
      to: Address.parse(DAILY_CHECKIN_CONTRACT_ADDRESS),
      value: toNano(DAILY_CHECKIN_CONTRACT_TRANSFER_VALUE),
      body: beginCell()
        .storeUint(0, 32)
        .storeStringTail(DAILY_CHECKIN_CONTRACT_LABEL)
        .endCell(),
    };

    const tx = this.getTx(args, now);

    return tx;
  };

  public getCreateJettonContractTx = async (
    input: CreateContractInput,
    now: number,
  ) => {
    try {
      if (!this.walletAddress) {
        throw this.onError(
          ErrorCode.OC_WALLET_ADDRESS_MISSING,
          ErrorCode.OC_WALLET_ADDRESS_MISSING,
        );
      }

      let metadata: Cell;

      const { memeId, ...inputWithoutOffchainTokenId } = input;

      try {
        metadata = buildOnchainMetadata(inputWithoutOffchainTokenId);
      } catch (e: any) {
        throw this.onError(e, ErrorCode.OC_JETTON_METADATA_FAILED);
      }

      // @TODO: take buyAmount | MAKE NONCE TYPE SAFE
      const sig = await this.getSignature('deployJettonContract', {
        ownerAddress: Address.parse(this.walletAddress),
        memeId: BigInt(memeId),
        buyAmount: -1, // @TODO: remove when the new api is released if unset
      });

      const message = {
        $$type: 'JettonDeploy',
        queryId: BigInt(Date.now()),
        metadata,
        ...sig,
      } as const;

      const body = beginCell()
        .store(storeBuilders.createJettonContract(message))
        .endCell();
      const args = this.getAdminSenderArgs(body);
      const tx = this.getTx(args, now);

      console.log(tx);
      return tx;
    } catch (e: any) {
      if (!isHandledError(e)) {
        console.error(e);
      }
      throw e;
    }
  };

  // private getTransferMessage = async (
  //   $$type: 'AdminCallTransferJetton' | 'AdminCallSellJetton',
  //   input: TransferTokenInput,
  // ) => {
  //   const sig = await this.getSignature(input);

  //   const message = {
  //     $$type,
  //     queryId: BigInt(Date.now()),
  //     ...sig,
  //   };
  //   return message;
  // };

  public getBuyTokenTx = async (input: BuyTokenInput, now: number) => {
    const sig = await this.getSignature('buyToken', input);

    // @TODO: implement metadata
    const message = {
      $$type: 'AdminCallTransferJetton',
      queryId: BigInt(Date.now()),
      // @TODO: Review this
      metadata: beginCell().endCell(),
      ...sig,
    } as const;

    const body = beginCell().store(storeBuilders.buyToken(message)).endCell();
    const args = this.getAdminSenderArgs(body);
    const tx = this.getTx(args, now);
    return tx;
  };

  public getSellTokenTx = async (input: SellTokenInput, now: number) => {
    const sig = await this.getSignature('sellToken', input);

    const message = {
      $$type: 'AdminCallSellJetton',
      queryId: BigInt(Date.now()),
      ...sig,
    } as const;

    const body = beginCell().store(storeBuilders.sellToken(message)).endCell();
    const args = this.getAdminSenderArgs(body);
    const tx = this.getTx(args, now);
    return tx;
  };

  // ==============================================================
  // ====================== CONTRACT GETTERS ======================
  // ==============================================================

  private AdminContractGetter = async (
    method: Getters,
    tuples: Optional<StackTuple> = undefined,
  ): Promise<Base64 | undefined> => {
    try {
      const stack = tuples ? [tuples] : [];

      const response: any = await this.pubClient.provider.send('runGetMethod', {
        address: TonProvider.adminContractAddress,
        method,
        stack,
      });
      // This type was copied from the observed response; Not guaranteed that every request will have this response;
      // The type that comes from the `send` method does not match the data.
      const data = response as GetContractAddressByOffchainIdResponse;
      console.log('AdminContractGetter', { data });
      const resStack = data?.stack?.[0]?.[1];
      const resObj = resStack?.object;
      const resObjData = resObj?.data;
      const b64 = resObjData?.b64;
      return b64;
    } catch (e) {
      console.error(`Failed to execute admin getter '${method}'`, e);
      throw e;
    }
  };

  getContactAddressList = async () => {
    try {
      const response = await this.AdminContractGetter(Getters.listContracts);
      // Make sure we remove any undefineds before trim and any empty strings after the trim
      const contractsCSV =
        response &&
        atob(response)
          .split(',')
          .filter(Boolean)
          .map((s) => s.trim())
          .filter(Boolean);
      return contractsCSV ?? [];
    } catch (error) {
      throw error;
    }
  };

  getJettonContractAddress = async (offchainTokenId: string) => {
    try {
      // @TODO: Talk to santosh about this
      const bigString = offchainTokenId; // stringToBigInt(offchainTokenId);

      const stack: StackTuple = ['num', Number(BigInt(bigString).toString())];

      const response = await this.AdminContractGetter(
        Getters.getJettonContract,
        stack,
      );

      if (!response) {
        return undefined;
      }

      const buff = Buffer.from(response, 'base64');
      const cell = beginCell().storeBuffer(buff).endCell();
      const slice = cell.asSlice();
      const rawAddress = slice.loadAddress();
      const address = Address.normalize(rawAddress);

      return address;
    } catch (error) {
      throw error;
    }
  };

  getPong = async () => {
    try {
      const response = await this.AdminContractGetter(Getters.pong);

      if (!response) {
        return undefined;
      }

      const buff = Buffer.from(response, 'base64');
      const cell = beginCell().storeBuffer(buff).endCell();
      const slice = cell.asSlice();
      const rawAddress = slice.loadAddress();
      const address = Address.normalize(rawAddress);

      return address;
    } catch (error) {
      throw error;
    }
  };

  getPingTx = (ping: string, now: number) => {
    const message = {
      $$type: 'Ping',
      queryId: BigInt(Date.now()),
      ping,
    } as const;

    const body = beginCell().store(storeBuilders.ping(message)).endCell();
    const args = this.getAdminSenderArgs(body, 0.1);
    const tx = this.getTx(args, now);
    return tx;
  };

  // JETTON (TOKEN) CONTRACTS

  // THIS IS UNTESTED!!!
  getJettonTokenSupply = async (jettonContractAddress: string) => {
    const jettonContract = Address.parse(jettonContractAddress);
    try {
      // Replace 'getMethodName' with the actual getter method of the contract
      const result = await this.pubClient.provider.send('runGetMethod', {
        address: jettonContract.toString(),
        method: Getters.getTokenBuyPrice,
        stack: [['num', 1000]],
      });

      const r = result as any;

      const t = r.stack ? BigInt(r.stack[0][1]) : undefined;

      console.log('Getter result:', result);
    } catch (error) {
      console.error('Error calling getter:', error);
    }
  };
}
