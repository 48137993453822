import { createAsyncGetters } from './createAsyncGetters';
import { followingsAsyncGetters } from './features/followings/followings.asyncGetters';
import { friendsAsyncGetters } from './features/friends/friends.asyncGetters';
import { gameAsyncGetters } from './features/game/game.asyncGetters';
import { playerAsyncGetters } from './features/game/player.asyncGetters';
import { offchainTradingAsyncGetters } from './features/offchainTrading/offchainTrading.asyncGetters';
import { tradingMemeAsyncGetters } from './features/tradingMeme/tradingMeme.asyncGetters';
import { onchainTxsAsyncGetters } from './features/onchainTxs/onchainTxs.asyncGetters';
// import { teamAsyncGetters } from './features/teams/teams.asyncGetters';

export const asyncGetters = createAsyncGetters({
  ...gameAsyncGetters,
  ...playerAsyncGetters,
  ...offchainTradingAsyncGetters,
  ...friendsAsyncGetters,
  ...followingsAsyncGetters,
  ...tradingMemeAsyncGetters,
  ...onchainTxsAsyncGetters,
  // @todo: remove (POST SEASON 2 MIGRATION)
  // ...teamAsyncGetters,
});

export default asyncGetters;
